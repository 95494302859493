import Box from 'Components/Atoms/Box';
import { useVoucher } from 'Contexts/VoucherContext';
import React, { useMemo } from 'react';

const DesignPicker: React.FC<{
  value: string;
  onChange: (value: string) => void;
  voucherId: string;
}> = ({ value, onChange, voucherId }) => {
  const ctx = useVoucher();

  const designs = useMemo(() => {
    const voucher = ctx.vouchers.data.find((v) => v.id === voucherId);

    if (!voucher) return [];

    const template = ctx.templates.data.find(
      (t) => t.id === voucher.templateId
    );

    return template?.designs ?? [];
  }, [ctx.templates.data, voucherId]);

  if (!designs.length) return <></>;

  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 8,
      }}
    >
      {designs.map((d) => (
        <Box
          key={d.id}
          style={{
            border:
              value === d.id
                ? '1px solid var(--color-text)'
                : '1px solid transparent',
            height: 80,
            borderRadius: 4,
            cursor: 'pointer',
            backgroundImage: `url(${d.image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onClick={() => onChange(d.id)}
        ></Box>
      ))}
    </Box>
  );
};

export default DesignPicker;
