import React, { useState } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import Card from 'Components/Molecules/Card';
import Typography from 'Components/Atoms/Typography';
import IconButton from 'Components/Atoms/IconButton';
import { Close } from 'Components/Atoms/Icons';
import { Reservation, Table } from 'types/reservations';
import { noop } from 'utils/helper';
import TableButtons from './Components/TableButtons';
import TableSchedule from './Components/TableSchedule';
import { ReservationActionTypes } from 'Contexts/ReservationContext';
import { useTableInfo } from 'CustomHooks/useTableInfo';

export type TableSideBarProps = {
  start: number;
  close: number;
  blocked: boolean;
  reservations: Reservation[];
  table: Table | null;
  name: string;
  onWalkin: (tables: string[], space?: string) => void;
  onToggleBlock: (id: string, name: string) => void;
  onCloseSideBar: () => void;
  setcurrentReservation: (id: string | null) => void;
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  id: string;
  currentTime: string | null;
};

const TableSideBar: React.FC<TableSideBarProps> = ({
  start,
  close,
  blocked = false,
  reservations,
  name,
  onWalkin = noop,
  onToggleBlock = noop,
  id,
  currentTime = null,
  onCloseSideBar = noop,
  table,
  onReservationAction = noop,
  setcurrentReservation,
}) => {
  const [hasOverflow, setHasOverflow] = useState<{
    bottom: boolean;
    top: boolean;
  }>({ top: false, bottom: false });

  const handleScrolling = (
    event: React.UIEvent<HTMLDivElement | HTMLLabelElement | HTMLFormElement>
  ) => {
    if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop ===
      event.currentTarget.clientHeight
    ) {
      setHasOverflow({ top: true, bottom: false });
    } else if (event.currentTarget.scrollTop === 0) {
      setHasOverflow({ top: false, bottom: true });
    } else if (
      event.currentTarget.scrollHeight - event.currentTarget.scrollTop >
        event.currentTarget.clientHeight &&
      event.currentTarget.scrollTop > 0
    ) {
      setHasOverflow({ top: true, bottom: true });
    }
  };

  const tableInfo = useTableInfo(name);

  console.log('tableInfo', tableInfo);

  const filteredReservations = reservations.filter((r) => !r.done);

  return (
    <Card
      style={{ borderRadius: 0, height: '100%' }}
      hideHeader
      subHeader={
        <Box padding="md" className="space-between">
          <Box flex>
            <Typography
              variant="h5"
              weight="bold"
              style={{ marginRight: 8 }}
              translation="reservations"
            >
              Table No.
            </Typography>
            <Typography variant="h5" weight="bold" color="primary">
              {name}
            </Typography>
          </Box>
          <IconButton
            color="subdued"
            size="small"
            onClick={() => onCloseSideBar()}
          >
            <Close />
          </IconButton>
        </Box>
      }
      bodyProps={{
        style: {
          position: 'relative',
          height: 'calc(100% - 60px)',
        },
        className: 'space-between flex-column',
      }}
    >
      {hasOverflow.top && <div className="topOverflowGradient"></div>}

      {
        <TableSchedule
          {...{
            start,
            close,
            reservations: filteredReservations,
            currentTime,
            setHasOverflow,
            handleScrolling,
            setcurrentReservation,
            onReservationAction,
            tableInfo,
          }}
        />
      }
      {hasOverflow.bottom && <div className="bottomOverflowGradient"></div>}
      {blocked && (
        <Box className="blocked" padding="md">
          <Typography variant="h5" textAlign="center">
            Table is blocked
          </Typography>
        </Box>
      )}
      <TableButtons
        {...{ onWalkin, onToggleBlock, blocked, id, name, space: table?.space }}
      />
    </Card>
  );
};

export default TableSideBar;
