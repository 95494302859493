import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { CurrentStatus, Reservation, Table } from 'types/reservations';
import TableName from './Components/TableName';
import { classHelper, maxMin, timeToBlock } from 'utils/helper';
import Time from './Components/Time';
import SwitchReservation from './Components/SwitchReservation';
import {
  ControlPointDuplicate,
  ExposurePlus1,
  ExposurePlus2,
  PaymentRounded,
  RestaurantRounded,
} from '@material-ui/icons';
import PaidIcon from 'Components/Atoms/Icons/Paid';
import { CuttleryIcon } from 'Components/Atoms/Icons';
import { useDrop } from 'CustomHooks/useDragAndDrop';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import useAlert from 'CustomHooks/useAlert';
import useRestaurant from 'CustomHooks/useRestaurant';

const DEFAULT_TABLE_PROPS: Record<
  string,
  { tableColor: string; chairColor: string; textColor: string }
> = {
  none: {
    tableColor: 'var(--color-table-none)',
    chairColor: 'var(--color-table-none-chair)',
    textColor: 'var(--color-table-none-text)',
  },
  seated: {
    tableColor: '#bb014e',
    chairColor: '#a90046',
    textColor: 'var(--color-white)',
  },
  partlySeated: {
    tableColor: '#D161A4',
    chairColor: '#DF90BF',
    textColor: 'var(--color-white)',
  },
  paid: {
    tableColor: '#5E01BB',
    chairColor: '#5500A9',
    textColor: 'var(--color-white)',
  },
  hasOrdered: {
    tableColor: '#bb014e',
    chairColor: '#a90046',
    textColor: 'var(--color-white)',
  },
  overdue: {
    tableColor: '#f3a712',
    chairColor: '#f4b02a',
    textColor: 'var(--color-white)',
  },
  upcoming: {
    tableColor: '#017957',
    chairColor: '#005d43',
    textColor: 'var(--color-white)',
  },
  nonbookable: {
    tableColor: 'var(--color-table-nonbookable)',
    chairColor: 'var(--color-table-nonbookable-chair)',
    textColor: 'var(--color-table-nonbookable-text)',
  },
  outOfTime: {
    tableColor: '#8B0000',
    chairColor: '#7A0000',
    textColor: 'var(--color-white)',
  },
};

export type NewTableProps = {
  table: Table;
  size: number;
  blocked?: boolean;
  active?: boolean;
  touchingX?: string[];
  touchingY?: string[];
  times?: {
    id: string;
    color: string;
    textColor: string;
    time: string;
    guests: number;
    status?: CurrentStatus;
    from: number;
    till: number;
    fixed: boolean;
    partlySeated?: boolean;
    customStatus?: string;
  }[];
  timeline?: {
    current: number;
    total: number;
  } | null;
  alert?: boolean;
  status?: CurrentStatus | 'nonbookable' | string;
  style?: React.CSSProperties;
  available?: boolean;
  switchReservation?: 'blocked' | string;
  alertMode?: boolean;
};

const timeOffset: Record<string, number> = {
  '2': -0.5,
  '2S': 0,
  '4': 0,
  CouchL: 0,
  CouchM: 0,
  CouchR: 0,
  '2Round': 0,
  '3Round': 0,
  Bar1: 0,
  '4Round': 0,
  '4L': 0, //1,
  '6': 0, //2,
  '6L': 1, // 1.7
  '8L': 0, //3.5,
  '8Long': 1, //3.5,
};

const TableSVG: React.FC<{
  type: string;
  size: number | string;
  style?: React.CSSProperties;
  tableColor: string;
  chairColor: string;
  id: string;
}> = ({ id, type, size, style, tableColor, chairColor }) => {
  switch (type) {
    case '2': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 220 220"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="220" height="220" rx="110" className="bg-circle" />
          <rect
            x="40"
            y="54.0001"
            width="140"
            height="112"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M180 87.6H184C185.105 87.6 186 88.4955 186 89.6V130.4C186 131.505 185.105 132.4 184 132.4H180V87.6Z"
            fill={chairColor}
          />
          <path
            d="M34 89.6C34 88.4955 34.8954 87.6 36 87.6H40V132.4H36C34.8954 132.4 34 131.505 34 130.4V89.6Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '2S': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 200 200"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="200" height="200" rx="100" className="bg-circle" />
          <rect
            x="25"
            y="52"
            width="150"
            height="90"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M48 142H93V146C93 147.105 92.1046 148 91 148H50C48.8954 148 48 147.105 48 146V142Z"
            fill={chairColor}
          />
          <path
            d="M114 142H159V146C159 147.105 158.105 148 157 148H116C114.895 148 114 147.105 114 146V142Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case 'Bar': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 200 200"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="200" height="200" rx="100" className="bg-circle" />
          <rect
            x="25"
            y="36.9999"
            width="150"
            height="80"
            rx="2"
            fill={tableColor}
          />
          <rect
            x="47"
            y="123"
            width="40"
            height="40"
            rx="20"
            fill={chairColor}
          />
          <rect
            x="113"
            y="123"
            width="40"
            height="40"
            rx="20"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '4': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 240 240"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="240" height="240" rx="120" className="bg-circle" />
          <rect
            x="40"
            y="40"
            width="160"
            height="160"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M200 98H204C205.105 98 206 98.8954 206 100V140.8C206 141.905 205.105 142.8 204 142.8H200V98Z"
            fill={chairColor}
          />
          <path
            d="M34 100C34 98.8954 34.8954 98 36 98H40V142.8H36C34.8954 142.8 34 141.905 34 140.8V100Z"
            fill={chairColor}
          />
          <path
            d="M98 36C98 34.8954 98.8954 34 100 34H141C142.105 34 143 34.8954 143 36V40H98V36Z"
            fill={chairColor}
          />
          <path
            d="M98 199H143V203C143 204.105 142.105 205 141 205H100C98.8954 205 98 204.105 98 203V199Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '4Round': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 225 225"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="225" height="225" rx="113" className="bg-circle" />
          <path
            d="M189 91H193C194.105 91 195 91.8954 195 93V133.8C195 134.905 194.105 135.8 193 135.8H189V91Z"
            fill={chairColor}
          />
          <path
            d="M29 93C29 91.8954 29.8954 91 31 91H35V135.8H31C29.8954 135.8 29 134.905 29 133.8L29 93Z"
            fill={chairColor}
          />
          <path
            d="M90 32C90 30.8954 90.8954 30 92 30L133 30C134.105 30 135 30.8954 135 32V36H90V32Z"
            fill={chairColor}
          />
          <path
            d="M90 190H135V194C135 195.105 134.105 196 133 196H92C90.8954 196 90 195.105 90 194V190Z"
            fill={chairColor}
          />
          <rect
            x="32"
            y="33"
            width="160"
            height="160"
            rx="80"
            fill={tableColor}
          />
        </svg>
      );
    }
    case '4L': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 300 300"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="300" height="300" rx="150" className="bg-circle" />
          <rect
            x="35.0001"
            y="80.0002"
            width="230"
            height="140"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M69.0001 77.0002C69.0001 75.8956 69.8955 75.0002 71.0001 75.0002H119.381C120.486 75.0002 121.381 75.8956 121.381 77.0002V80.2633H69.0001V77.0002Z"
            fill={chairColor}
          />
          <path
            d="M179 77.0002C179 75.8956 179.895 75.0002 181 75.0002H229.381C230.486 75.0002 231.381 75.8956 231.381 77.0002V80.2633H179V77.0002Z"
            fill={chairColor}
          />
          <path
            d="M69.0001 219.737H121.381V223C121.381 224.105 120.486 225 119.381 225H71.0001C69.8955 225 69.0001 224.105 69.0001 223V219.737Z"
            fill={chairColor}
          />
          <path
            d="M179 219.737H231.381V223C231.381 224.105 230.486 225 229.381 225H181C179.895 225 179 224.105 179 223V219.737Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '6': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 320 320"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="320" height="320" rx="160" className="bg-circle" />
          <rect
            x="44.9998"
            y="90.0001"
            width="230"
            height="140"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M78.9998 87.0001C78.9998 85.8955 79.8952 85.0001 80.9998 85.0001H129.381C130.485 85.0001 131.381 85.8955 131.381 87.0001V90.2632H78.9998V87.0001Z"
            fill={chairColor}
          />
          <path
            d="M189 87.0001C189 85.8955 189.895 85.0001 191 85.0001H239.381C240.485 85.0001 241.381 85.8955 241.381 87.0001V90.2632H189V87.0001Z"
            fill={chairColor}
          />
          <path
            d="M78.9998 229.737H131.381V233C131.381 234.105 130.485 235 129.381 235H80.9998C79.8952 235 78.9998 234.105 78.9998 233V229.737Z"
            fill={chairColor}
          />
          <path
            d="M189 229.737H241.381V233C241.381 234.105 240.485 235 239.381 235H191C189.895 235 189 234.105 189 233V229.737Z"
            fill={chairColor}
          />
          <path
            d="M275 138H279C280.104 138 281 138.895 281 140V180.8C281 181.905 280.104 182.8 279 182.8H275V138Z"
            fill={chairColor}
          />
          <path
            d="M38.9998 140C38.9998 138.895 39.8952 138 40.9998 138H44.9998L44.9998 182.8H40.9998C39.8952 182.8 38.9998 181.905 38.9998 180.8L38.9998 140Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '6L': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 270 270"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <rect
            x="20"
            y="65.0001"
            width="230"
            height="140"
            rx="2"
            fill={tableColor}
          />
          <path
            d="M24.9565 62.0001C24.9565 60.8955 25.852 60.0001 26.9565 60.0001L79.8924 60.0001C80.9969 60.0001 81.8924 60.8955 81.8924 62.0001V65.2632H24.9565V62.0001Z"
            fill={chairColor}
          />
          <path
            d="M188.522 62.0001C188.522 60.8955 189.417 60.0001 190.522 60.0001L243.458 60.0001C244.562 60.0001 245.458 60.8955 245.458 62.0001V65.2632H188.522V62.0001Z"
            fill={chairColor}
          />
          <path
            d="M107 62C107 60.8954 107.895 60 109 60L161.936 60C163.04 60 163.936 60.8954 163.936 62V65.2632H107V62Z"
            fill={chairColor}
          />
          <path
            d="M24.9565 204.737H81.8924V208C81.8924 209.105 80.9969 210 79.8924 210H26.9565C25.852 210 24.9565 209.105 24.9565 208V204.737Z"
            fill={chairColor}
          />
          <path
            d="M188.522 204.737H245.458V208C245.458 209.105 244.562 210 243.458 210H190.522C189.417 210 188.522 209.105 188.522 208V204.737Z"
            fill={chairColor}
          />
          <path
            id={`${id}__chair_bottom1_2`}
            d="M107 204.737H163.936V208C163.936 209.105 163.04 210 161.936 210H109C107.895 210 107 209.105 107 208V204.737Z"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '8': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 320 320"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="320" height="320" rx="160" className="bg-circle" />
          <rect
            x="240.587"
            y="279.403"
            width="280"
            height="52.0661"
            rx="2"
            transform="rotate(-135 240.587 279.403)"
            fill={chairColor}
          />
          <rect
            x="133.967"
            y="302"
            width="280"
            height="52.0661"
            rx="2"
            transform="rotate(-90 133.967 302)"
            fill={chairColor}
          />
          <rect
            x="42.5968"
            y="242.587"
            width="280"
            height="52.0661"
            rx="2"
            transform="rotate(-45 42.5968 242.587)"
            fill={chairColor}
          />
          <rect
            x="19.9999"
            y="135.967"
            width="280"
            height="52.0661"
            rx="2"
            fill={chairColor}
          />
          <rect
            x="26.942"
            y="28.3636"
            width="266.116"
            height="266.116"
            rx="133.058"
            fill={tableColor}
          />
        </svg>
      );
    }
    case 'CouchL': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 270 270"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="270" height="270" rx="135" className="bg-circle" />
          <rect
            x="65"
            y="69"
            width="171"
            height="78"
            rx="4"
            fill={tableColor}
          />
          <rect
            x="33"
            y="61.0896"
            width="38.7239"
            height="88.6712"
            rx="4"
            fill={chairColor}
          />
          <rect
            x="49.6475"
            y="44"
            width="186.622"
            height="37.4163"
            rx="4"
            fill={chairColor}
          />
          <rect
            x="81.6393"
            y="155.416"
            width="131"
            height="70"
            rx="4"
            fill={chairColor}
          />
        </svg>
      );
    }
    case 'CouchM': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 270 270"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="270" height="270" rx="135" className="bg-circle" />
          <rect
            id="ID__table"
            x="33"
            y="69"
            width="203"
            height="78"
            rx="4"
            fill={tableColor}
          />
          <rect
            id="ID__chair_top1"
            x="33"
            y="44"
            width="203.164"
            height="37.4163"
            rx="4"
            fill={chairColor}
          />
          <rect
            id="ID__chair_bottom"
            x="67.8276"
            y="155.416"
            width="142.612"
            height="70"
            rx="4"
            fill={chairColor}
          />
        </svg>
      );
    }
    case 'CouchR': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 270 270"
          fill="none"
          version="1.1"
          style={style}
          xmlns="http://www.w3.org/2000/svg"
          id={id}
        >
          <rect width="270" height="270" rx="135" className="bg-circle" />
          <rect
            id="ID__table"
            x="50"
            y="69"
            width="174"
            height="78"
            rx="4"
            fill={tableColor}
          />
          <rect
            id="ID__chair_top"
            x="214"
            y="65"
            width="38.7239"
            height="88.6712"
            rx="4"
            fill={chairColor}
          />
          <rect
            id="ID__chair_top1"
            x="49.6475"
            y="44"
            width="186.622"
            height="37.4163"
            rx="4"
            fill={chairColor}
          />
          <rect
            id="ID__chair_bottom"
            x="81.6393"
            y="155.416"
            width="131"
            height="70"
            rx="4"
            fill={chairColor}
          />
        </svg>
      );
    }
    case '2Round': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="-5 -5 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <rect
            id={`${id}__active`}
            className="tableActive"
            // strokeOpacity={active ? 1 : 0}
            x="2"
            y="2"
            width="87"
            height="87"
            rx="4"
            // stroke={activeColor}
            strokeWidth="2"
          />

          <g
            id={`${id}__rotate`}
            // transform={`rotate(${direction} 45 45), translate(21 21)`}
          >
            <g id={`${id}__chairs`}>
              <path
                d="M47.2681 18.2803V18.2803C48.2262 18.2808 49.0026 19.058 49.0021 20.0162L48.9967 29.9863C48.9962 30.9444 48.219 31.7208 47.2608 31.7203V31.7203L47.2681 18.2803Z"
                fill="#8C9AAC"
              />
              <path
                d="M1.00199 19.9898C1.00251 19.0316 1.77969 18.2553 2.73787 18.2558V18.2558L2.7306 31.6958V31.6958C1.77242 31.6953 0.996081 30.9181 0.996599 29.9599L1.00199 19.9898Z"
                fill="#8C9AAC"
              />
            </g>
            <rect
              x="1.87988"
              y="0.855469"
              width="46"
              height="45"
              rx="23.1326"
              transform="rotate(0.0309864 1.87988 0.855469)"
              fill={tableColor}
            />
          </g>
        </svg>
      );
    }
    case '3Round': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 90 90"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <g
            id={`${id}__rotate`}
            // transform={`rotate(${direction} 45 45), translate(22 22)`}
          >
            <g id={`${id}__chairs`} transform="translate(21 21.5)">
              <path
                d="M46.0959 17.7812V17.7812C47.0633 17.7897 47.8406 18.5808 47.8321 19.5481L47.7504 28.8731C47.7419 29.8404 46.9508 30.6177 45.9835 30.6093V30.6093L46.0959 17.7812Z"
                fill={chairColor}
              />
              <path
                d="M15.2622 1.00843C16.0878 0.530774 17.1557 0.806149 17.6473 1.6235V1.6235L6.38399 8.13987V8.13987C5.89233 7.32252 6.16304 6.27271 6.98866 5.79505L15.2622 1.00843Z"
                fill={chairColor}
              />
              <path
                d="M7.20947 39.7148L18.5295 46.2572V46.2572C18.0386 47.0747 16.9714 47.3506 16.1458 46.8735L7.81551 42.059C6.98993 41.5818 6.7186 40.5323 7.20947 39.7148V39.7148Z"
                fill={chairColor}
              />
            </g>
            <rect
              x="22"
              y="22"
              width="46"
              height="46"
              rx="22.9081"
              transform="rotate(0.502237 0.410156 0.773438)"
              fill={tableColor}
            />
          </g>
        </svg>
      );
    }
    case 'Bar1': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 182 182 "
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <rect
            id={`${id}__active`}
            className="tableActive"
            // strokeOpacity={active ? 1 : 0}
            x="2"
            y="2"
            width="176"
            height="176"
            rx="4"
            // stroke={activeColor}
            strokeWidth="4"
          />

          <g
            id={`${id}__rotate`}
            // transform={`rotate(${direction} 90 90)`}
          >
            <rect
              x="50"
              y="28"
              width="80"
              height="80"
              rx="2"
              fill={tableColor}
            />
            <rect
              x="73"
              y="114"
              width="40"
              height="40"
              rx="20"
              fill={chairColor}
            />
          </g>
        </svg>
      );
    }
    case '8L': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 144 144"
          fill="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <rect width="144" height="144" rx="72" className="bg-circle" />
          <g id={`${id}__rotate`} transform={`translate(12 45)`}>
            <rect
              id={`${id}__table`}
              x="3"
              y="3"
              width="114"
              height="48"
              rx="2"
              fill={tableColor}
            />
            <g id={`${id}__chairs`}>
              <path
                id={`${id}__chair_top1`}
                d="M13.0078 51H37.0078V52.2C37.0078 53.3046 36.1124 54.2 35.0078 54.2H15.0078C13.9032 54.2 13.0078 53.3046 13.0078 52.2V51Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M13.0078 2C13.0078 0.895432 13.9032 0 15.0078 0L35.0078 0C36.1124 0 37.0078 0.89543 37.0078 2V3.2L13.0078 3.2V2Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M83.0078 2C83.0078 0.895432 83.9032 0 85.0078 0L105.008 0C106.112 0 107.008 0.89543 107.008 2V3.2L83.0078 3.2V2Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M118.208 15C119.312 15 120.208 15.8954 120.208 17L120.208 37C120.208 38.1046 119.312 39 118.208 39L117.008 39L117.008 15L118.208 15Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M2 39C0.895432 39 -3.91405e-08 38.1046 -8.74228e-08 37L-9.61651e-07 17C-1.00993e-06 15.8954 0.895429 15 2 15L3.2 15L3.2 39L2 39Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M48.0078 51H72.0078V52.2C72.0078 53.3046 71.1124 54.2 70.0078 54.2H50.0078C48.9032 54.2 48.0078 53.3046 48.0078 52.2V51Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M83.0078 51H107.008V52.2C107.008 53.3046 106.112 54.2 105.008 54.2H85.0078C83.9032 54.2 83.0078 53.3046 83.0078 52.2V51Z"
                fill={chairColor}
              />
              <path
                id={`${id}__chair_top1`}
                d="M48.0078 2C48.0078 0.895432 48.9032 0 50.0078 0L70.0078 0C71.1124 0 72.0078 0.89543 72.0078 2V3.2L48.0078 3.2V2Z"
                fill={chairColor}
              />
            </g>
          </g>
        </svg>
      );
    }
    case '8Long': {
      return (
        <svg
          width={size}
          height={size}
          viewBox="0 0 420 420"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          style={style}
        >
          <rect width="420" height="420" rx="210" className="bg-circle" />
          <g id={`${id}__rotate`} transform={`translate(0 -10)`}>
            <rect
              y="7.75"
              width="380"
              height="125"
              rx="2"
              fill={tableColor}
              transform={`translate(20 150)`}
            />
            <g id={`${id}__chairs`}>
              <path
                transform={`translate(20 150)`}
                d="M25.8503 131.734H87.843V138C87.843 139.105 86.9475 140 85.843 140H27.8503C26.7458 140 25.8503 139.105 25.8503 138V131.734Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M25.8503 2C25.8503 0.895429 26.7458 0 27.8503 0L85.843 0C86.9475 0 87.843 0.895431 87.843 2V8.26568L25.8503 8.26568V2Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M206.642 2C206.642 0.895429 207.538 0 208.642 0L266.635 0C267.739 0 268.635 0.895431 268.635 2V8.26568L206.642 8.26568V2Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M297.048 2C297.048 0.895429 297.944 0 299.048 0L357.041 0C358.145 0 359.041 0.895431 359.041 2V8.26568L297.048 8.26568V2Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M116.256 131.734H178.249V138C178.249 139.105 177.353 140 176.249 140H118.256C117.152 140 116.256 139.105 116.256 138V131.734Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M206.642 131.734H268.635V138C268.635 139.105 267.739 140 266.635 140H208.642C207.538 140 206.642 139.105 206.642 138V131.734Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M297.048 131.734H359.041V138C359.041 139.105 358.145 140 357.041 140H299.048C297.944 140 297.048 139.105 297.048 138V131.734Z"
                fill={chairColor}
              />
              <path
                transform={`translate(20 150)`}
                d="M116.256 2C116.256 0.895429 117.152 0 118.256 0L176.249 0C177.353 0 178.249 0.895431 178.249 2V8.26568L116.256 8.26568V2Z"
                fill={chairColor}
              />
            </g>
          </g>
        </svg>
      );
    }
    default:
      return <></>;
  }
};

const NewTable: React.FC<NewTableProps> = ({
  alertMode = false,
  touchingX = [],
  touchingY = [],
  table,
  size,
  blocked = false,
  active,
  times = [],
  timeline,
  alert: hasAlert = false,
  status = CurrentStatus.NORMAL,
  style,
  available = false,
  switchReservation,
}) => {
  const { experimentalFlags } = useRestaurant();

  const sizeInPx = size * table.coords.size;

  const partlySeated = times.some((x) => x.partlySeated);

  const outOfTime = timeline && timeline?.current >= timeline?.total;

  const customStatus = times?.[0]?.customStatus
    ? experimentalFlags?.data?.currentTableStati?.find(
        (st) => st.title === times?.[0]?.customStatus
      )
    : null;

  const colors = customStatus
    ? {
        tableColor: customStatus.color,
        chairColor: customStatus.chairColor,
        textColor: customStatus.textColor || '#fff',
      }
    : outOfTime
    ? DEFAULT_TABLE_PROPS['outOfTime']
    : DEFAULT_TABLE_PROPS?.[partlySeated ? 'partlySeated' : status] ||
      DEFAULT_TABLE_PROPS.none;

  const isPaid = status === CurrentStatus.PAID;

  const hasOrdered = status === CurrentStatus.HAS_ORDERED;

  const { handleAction, reservations } = useContext(ReservationContext);

  const alert = useAlert();

  const filteredTimes = experimentalFlags?.data?.hideTimeOfCurrentReservation
    ? times.filter(
        (x) =>
          x.status === CurrentStatus.NORMAL ||
          x.status === CurrentStatus.OVERDUE ||
          x.status === CurrentStatus.UPCOMING
      )
    : times;

  const { canDrop, drop, isOver } = useDrop<{
    id: string;
    from: number;
    till: number;
    guests: number;
  }>(
    (r) => {
      console.log({ table, r });

      if (times.find((x) => x.id === r.id)) return;

      let overlapping = times.filter(
        (x) =>
          (x.from <= r.from && x.till >= r.from) ||
          (x.from >= r.from && x.from <= r.till)
      );

      let resa = reservations.find((x) => x.id === r.id);

      let fixed = !!resa?.fixed;

      if (overlapping.length) {
        if (overlapping.length === 1) {
          if (!resa) return;

          alert({
            title: fixed
              ? 'Die Reservation ist fixiert und überschneidet sich mit einer anderen Reservierung.'
              : 'Die Reservation überschneidet sich mit einer anderen Reservierung.',
            description: 'Willst du trotzdem fortfahren?',
            fullWidth: true,
            maxWidth: 'sm',
            actions: [
              { id: 'cancel', label: 'Abbrechen', onClick: () => {} },
              {
                id: 'trotzdem',
                label: 'Trotzdem platzieren',
                onClick: () => {
                  handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
                    tables: [table.name],
                    space: table.space,
                  });
                },
              },
              {
                id: 'swap',
                label: 'Tauschen',
                variant: 'primary',
                disabled: !!overlapping?.[0]?.fixed,
                onClick: () => {
                  handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
                    tables: [table.name],
                    space: table.space,
                  });

                  handleAction(
                    overlapping[0].id,
                    ReservationActionTypes.CHANGE_TABLE,
                    {
                      tables: resa?.tables,
                      space: resa?.space,
                    }
                  );
                },
              },
            ],
            onSubmit: () => {},
          });
        } else {
          alert({
            title: fixed
              ? 'Die Reservation ist fixiert und überschneidet sich mit mehreren anderen Reservierung.'
              : 'Die Reservation überschneidet sich mit mehreren anderen Reservierung.',
            description: 'Willst du trotzdem fortfahren?',
            fullWidth: true,
            maxWidth: 'sm',
            actions: [
              { id: 'cancel', label: 'Abbrechen', onClick: () => {} },
              {
                id: 'trotzdem',
                label: 'Trotzdem platzieren',
                onClick: () => {
                  handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
                    tables: [table.name],
                    space: table.space,
                  });
                },
                variant: 'primary',
              },
            ],
            onSubmit: () => {},
          });
        }

        return;
      }

      if (table.max < r.guests) {
        alert({
          title: fixed
            ? 'Die Reservierung ist fixiert und der Tisch hat nicht genug Platz für diese Gruppe.'
            : 'Der Tisch hat nicht genug Platz für diese Gruppe.',
          description: 'Willst du trotzdem fortfahren?',
          fullWidth: true,
          maxWidth: 'sm',
          actions: [
            { id: 'cancel', label: 'Abbrechen', onClick: () => {} },
            {
              id: 'trotzdem',
              label: 'Trotzdem platzieren',
              onClick: () => {
                handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
                  tables: [table.name],
                  space: table.space,
                });
              },
            },
            {
              id: 'weitereTische',
              label: 'Weitere Tische',
              variant: 'primary',
              onClick: () => {
                handleAction(r.id, ReservationActionTypes.RELOCATE, {
                  tables: [table.name],
                  space: table.space,
                });
              },
            },
          ],
          onSubmit: () => {},
        });
        return;
      }

      if (fixed) {
        alert({
          title: 'Die Reservation ist fixiert.',
          description: 'Willst du trotzdem fortfahren?',
          fullWidth: true,
          maxWidth: 'sm',
          actions: [
            { id: 'cancel', label: 'Abbrechen', onClick: () => {} },
            {
              id: 'trotzdem',
              label: 'Trotzdem platzieren',
              onClick: () => {
                handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
                  tables: [table.name],
                  space: table.space,
                });
              },
              variant: 'primary',
            },
          ],
          onSubmit: () => {},
        });
      }

      handleAction(r.id, ReservationActionTypes.CHANGE_TABLE, {
        tables: [table.name],
        space: table.space,
      });
    },
    ['reservation'],
    times
  );

  const { height, width, factor } = useMemo(() => {
    let size = sizeInPx;
    if (table.type === '8Long' || table.type === '8L') {
      let direction = Math.abs(table.coords.direction % 180);

      if (direction < 20 || direction > 160) {
        return {
          width: size,
          height: size * 0.55,
          factor: 0.55,
        };
      } else if (direction > 70 && direction < 110) {
        return {
          width: size * 0.55,
          height: size,
          factor: 0.55,
        };
      }

      return {
        width: size,
        height: size,
        factor: 0.55,
      };
    } else if (table.type === '8' || table.type === '4Round') {
      return {
        width: size,
        height: size,
        factor: 1,
      };
    } else {
      return {
        width: size,
        height: size,
        factor: 0.8,
      };
    }
  }, [sizeInPx, table]);

  return (
    <Box
      className={classHelper([
        'new-table',
        (available || canDrop) && 'available',
        active && 'active',
        isOver && 'isOver',
        table.type + '-' + size,
        'border-' + table.type.toLocaleLowerCase(),
        status,
      ])}
      style={{
        height,
        width,
        ...style,
        overflow:
          (height !== sizeInPx || width !== sizeInPx) && !available
            ? 'hidden'
            : undefined,
      }}
      ref={drop}
    >
      <div
        className="active-border"
        style={{
          height: sizeInPx * factor - 8,
          width: sizeInPx - 8,
          transform: `translate(calc(-50% + 2px), calc(-50% + 2px)) rotate(${
            table.coords.direction % 360
          }deg)`,
        }}
      ></div>
      <TableSVG
        type={table.type}
        size={sizeInPx}
        {...colors}
        id={table.id}
        style={{
          transform: `rotate(${table.coords.direction % 360}deg)`,
          position: 'absolute',
          top: (height - sizeInPx) / 2,
          left: (width - sizeInPx) / 2,
          zIndex: 1,
        }}
      />

      {isPaid && (
        <PaidIcon
          height={sizeInPx / 2}
          style={{
            color: '#fff',
            opacity: 0.3,
            position: 'absolute',
            zIndex: 1,
          }}
        />
      )}

      {hasOrdered && (
        <RestaurantRounded
          style={{
            color: '#fff',
            opacity: 0.3,
            position: 'absolute',
            zIndex: 1,
            width: sizeInPx / 2.5,
            height: sizeInPx / 2.5,
          }}
        />
      )}

      <TableName
        name={
          !!customStatus?.icon
            ? `${table.name} ${customStatus.icon}`
            : table.name
        }
        blocked={blocked}
        alert={alertMode && hasAlert}
        {...colors}
        size={size}
        timeline={!isPaid ? timeline : null}
      />
      {filteredTimes.length > 2 && size > 6 && (
        <span
          style={{
            position: 'absolute',
            zIndex: 1,
            right: maxMin(size, 10, 20),
            top: '50%',
            transform: 'translateY(-50%)',
            bottom: '50%',
            height: size * 2.5,
            width: size * 2.5,
            borderRadius: size * 1.25,
            fontSize: size * 1.5,
            lineHeight: size * 1.5,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'var(--color-secondary)',
            color: '#fff',
          }}
        >
          +{filteredTimes.length - 2}
        </span>
      )}
      {filteredTimes?.[0] && (
        <Time
          touching={touchingX}
          top={
            maxMin(size - 5, 5, 20) +
            (touchingY.length ? size : 0) +
            (timeOffset?.[table.type] ?? 0) * size
          }
          {...filteredTimes[0]}
          showGuestsAlways={
            experimentalFlags?.data?.showGuestsInTimeEvenOnLargeFloorplans
          }
          size={size}
        />
      )}
      {filteredTimes?.[1] && (
        <Time
          touching={touchingX}
          bottom={
            maxMin(size - 5, 5, 20) +
            (touchingY.length ? size : 0) +
            (timeOffset?.[table.type] ?? 0) * size
          }
          {...filteredTimes[1]}
          showGuestsAlways={
            experimentalFlags?.data?.showGuestsInTimeEvenOnLargeFloorplans
          }
          size={size}
        />
      )}

      {switchReservation && (
        <SwitchReservation
          switchReservation={
            !!switchReservation && switchReservation !== 'blocked'
          }
        />
      )}
    </Box>
  );
};

export default NewTable;
