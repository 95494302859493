import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import {
  CancelOutlined,
  CheckCircle,
  CheckCircleOutline,
  Seat,
  TableSwitch,
} from 'Components/Atoms/Icons';
import { CurrentStatus, Reservation } from 'types/reservations';
import {
  ReservationActionTypes,
  ReservationContext,
} from 'Contexts/ReservationContext';
import FixedReservationIcon from 'Components/Atoms/Icons/FixedReservationIcon';
import NotFixedReservationIcon from 'Components/Atoms/Icons/NotFixedReservationIcon';
import { dateHelper } from 'utils/helper';
import useAlert from 'CustomHooks/useAlert';
import { ConnectDragSource } from 'react-dnd';

export type ActionButtonsProps = {
  reservation: Reservation;
  currentStatus: '' | CurrentStatus;
  coloredLogo: boolean;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  id: string;
  drag?: ConnectDragSource | null;
};
const ActionButtons = ({
  reservation,
  currentStatus,
  coloredLogo,
  handleAction,
  drag = null,
  id,
}: ActionButtonsProps) => {
  const { Shuffle } = useContext(ReservationContext);

  const alert = useAlert();

  const fixed = useMemo(() => {
    if (!Shuffle.state) return false;
    if (Shuffle.state.fixedReservations.includes(reservation.id)) return true;

    return reservation.fixed || reservation.done || reservation.started;
  }, [Shuffle, reservation]);

  return (
    <Box flex style={{ justifyContent: 'flex-end', margin: '4px 0px' }}>
      {!Shuffle.state && !['failed', 'done'].includes(currentStatus) && (
        <>
          {reservation.status === 'pending' ||
          reservation.status === 'positive' ? (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAction(id, ReservationActionTypes.CONFIRM);
              }}
              style={{ marginRight: 17 }}
            >
              <CheckCircleOutline
                className={coloredLogo ? 'text-subdued' : ' text-white'}
              />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();

                if (reservation.fixed) {
                  alert({
                    title: 'Reservierung is fixiert',
                    description:
                      'Diese Reservierung ist fixiert willst du Sie trotzdem umplatzieren?',
                    onSubmit: () =>
                      handleAction(id, ReservationActionTypes.RELOCATE),
                  });
                } else {
                  handleAction(id, ReservationActionTypes.RELOCATE);
                }
              }}
              ref={drag}
              style={{ marginRight: 17 }}
            >
              <TableSwitch
                className={coloredLogo ? 'text-subdued' : ' text-white'}
              />
            </IconButton>
          )}

          {reservation.status === 'pending' ||
          reservation.status === 'positive' ? (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAction(id, ReservationActionTypes.REFUSE);
              }}
            >
              <CancelOutlined
                className={coloredLogo ? 'text-subdued' : ' text-white'}
              />
            </IconButton>
          ) : currentStatus === 'seated' ||
            currentStatus === 'paid' ||
            currentStatus === 'hasOrdered' ? (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAction(id, ReservationActionTypes.MARK_AS_DONE);
              }}
            >
              <CheckCircle
                className={coloredLogo ? 'text-subdued' : ' text-white'}
              />
            </IconButton>
          ) : (
            <IconButton
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleAction(id, ReservationActionTypes.SEAT);
              }}
            >
              <Seat
                style={{ opacity: reservation.date > dateHelper() ? 0.75 : 1 }}
                className={coloredLogo ? 'text-subdued' : ' text-white'}
              />
            </IconButton>
          )}
        </>
      )}
      {!!Shuffle.state && !['failed', 'done'].includes(currentStatus) && (
        <IconButton
          // size="small"
          onClick={(e) => {
            e.stopPropagation();
            Shuffle.toggleFixedReservation(reservation.id);
          }}
        >
          {fixed ? (
            <FixedReservationIcon
              style={{
                color: 'var(--color-secondary)',
              }}
            />
          ) : (
            <NotFixedReservationIcon
              style={{
                color: '#96A4B7',
                opacity: 0.5,
              }}
            />
          )}
        </IconButton>
      )}
    </Box>
  );
};

export default ActionButtons;
