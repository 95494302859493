import React, { useContext, useMemo } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import { CurrentStatus, Reservation } from 'types/reservations';
import Typography from 'Components/Atoms/Typography';
import {
  blockToTime,
  capitalizeWord,
  classHelper,
  dateHelper,
  noop,
  timeHelper,
  timeToBlock,
  toEuropeanDate,
} from 'utils/helper';
import { Ticket, WarningBar, WarningRounded } from 'Components/Atoms/Icons';
import AttrIcons from './Components/AttrIcons';
import ActionButtons from './Components/ActionButtons';
import {
  ReservationActionTypes,
  ReservationContext,
  useReservationContext,
} from 'Contexts/ReservationContext';
import { useHistory } from 'react-router';
import useIsLightReservations from 'CustomHooks/useIsLightReservations';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PermPhoneMsgRounded from '@material-ui/icons/PermPhoneMsgRounded';
import useRestaurant from 'CustomHooks/useRestaurant';
import Button from 'Components/Atoms/Button';
import { stringifyCustomDataValue } from '../ReservationSideBar/Components/ReservationDetails';
import { useDrag } from 'CustomHooks/useDragAndDrop';
import { DragPreviewImage } from 'react-dnd';
import Warning from '../Warning';

export type ReservationCardProps = {
  reservation: Reservation;
  active: boolean;
  hasWarnings: boolean;
  hideOccassion: boolean;
  handleAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  onClick?: (id: string | null) => void;
  occassions?: { title: string; id: string }[];
  isAnotherDay?: boolean;
};

function cleanCustomData(
  customData: Reservation['customData'],
  imported = false
) {
  return Object.entries(customData ?? {}).filter(([key]) => {
    let hideWhenImported = [
      'Art',
      'Bereiche',
      'Nachname',
      'Stornierungsgebühr',
      'E-Mail',
      'Vorname',
      'Datum',
      'Ende',
      'Beginn',
      'Tischnummer',
      'Reservierungsnr.',
      'Erstellungsdatum',
      'Personen',
      'Booking channel',
      'Booking taken by',
      'Booking taken',
      'Kundennr.',
      'Sprache',
      'Überprüft',
      'Abw. Verweilzeit',
      'Telefon',
      'Abweichende Verweildauer',
      'Bearbeiter',
      'Verspätet um',
    ];

    if (imported) {
      return !hideWhenImported.includes(key);
    }

    return !['customStatus'].includes(key);
  });
}

const ReservationCard = ({
  reservation,
  active,
  hasWarnings,
  hideOccassion,
  handleAction = noop,
  onClick = noop,
  occassions = [],
  isAnotherDay = false,
}: ReservationCardProps) => {
  const {
    id = '',
    currentStatus = '',
    time = '',
    guests = 0,
    guest,
    occassion = '',
    tables = [],
    date = '',
    feedbackRating,
    experienceVoucher,
  } = reservation;

  const { getAddOnOrders, Shuffle, currentTime } = useReservationContext();

  const [loading, setloading] = React.useState(false);

  const addOnOrders = useMemo(() => {
    return reservation?.orders ?? getAddOnOrders(id, false);
  }, [getAddOnOrders, id]);

  const { reservationSettings, experimentalFlags } = useRestaurant();

  const showReservationLength = !!reservationSettings?.showReservationLength;

  const history = useHistory();

  const coloredLogo = useMemo(() => {
    return ['none', 'done', 'failed', 'normal'].includes(currentStatus);
  }, [currentStatus, active]);

  const handleClick = () => {
    if (isMobile) {
      onClick(id);
    } else {
      if (active) {
        onClick(null);
      } else {
        onClick(id);
      }
    }
  };

  const lightReservations = useIsLightReservations();

  const occassionString =
    occassions?.find((o) => o.id === occassion)?.title || occassion;

  const goToReservation = () =>
    history.push(
      `/${reservation.restaurant}/reservations/${date}/${
        lightReservations ? 'reservationList' : 'tablePlan'
      }?reservationId=${reservation.id}`
    );

  const isMobile = useMediaQuery('(max-width: 450px)');

  const nameStr = `${guest.name} ${
    guest.company ? `(${guest.company})` : ''
  }`.slice(0, 24);

  const hasPayment =
    !!reservation.minimumConsumption ||
    !!reservation.ticketId ||
    !!addOnOrders.length ||
    !!reservation.hasAddOnOrders ||
    !!experienceVoucher;

  const additionalButtons = useMemo(() => {
    if (isAnotherDay || isMobile || reservation.date !== dateHelper())
      return [];

    if (reservation.partlySeated) {
      return [
        {
          label: 'Vollständig Platzieren',
          type: ReservationActionTypes.FULLY_SEAT,
        },
      ];
    } else if (
      reservation.currentStatus === CurrentStatus.OVERDUE ||
      reservation.currentStatus === CurrentStatus.UPCOMING ||
      (reservation.startTimeInBlocks - 2 <=
        timeToBlock(currentTime || timeHelper()) &&
        reservation.currentStatus === CurrentStatus.NORMAL)
    ) {
      return [
        {
          label: 'Teilw. Platzieren',
          type: ReservationActionTypes.PARTLY_SEAT,
          hide: reservation.guests < 3,
        },
        {
          label: '+15 Min',
          type: ReservationActionTypes.MARK_AS_LATE,
        },
      ].filter((x) => !x.hide);
    }
  }, [reservation, loading]);

  const { drag, opacity, preview } = useDrag<{
    id: string;
    from: number;
    till: number;
    guests: number;
  }>(
    {
      id: reservation.id,
      from: reservation.startTimeInBlocks,
      till: reservation.endTimeInBlocks,
      guests: reservation.guests,
    },
    'reservation',
    isMobile ||
      isAnotherDay ||
      reservation.done ||
      reservation.status === 'failed'
  );

  const currentCustomStatus = reservation?.customData?.customStatus
    ? experimentalFlags?.data?.currentTableStati?.find(
        (x) => x.title === reservation?.customData?.customStatus
      )
    : null;

  return (
    <Box
      underline
      id={id}
      elevation={0}
      className={`reservation-card resa-${currentStatus} ${
        active ? 'active' : ''
      } ${reservation.partlySeated ? 'partly-seated' : ''}`}
      style={
        !!currentCustomStatus
          ? {
              opacity,
              backgroundColor: active
                ? currentCustomStatus.chairColor
                : currentCustomStatus.color,
            }
          : { opacity }
      }
      onClick={!isAnotherDay ? handleClick : goToReservation}
      ref={drag}
    >
      <span
        className="drag-preview"
        style={{
          // display: opacity === 1 ? 'none' : 'inline-block',
          background: '#6369D1',
          borderRadius: 4,
          padding: 4,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: -100,
          opacity: opacity === 1 ? 1 : 0,
        }}
        // ref={preview}
      >
        <small>
          {reservation.time} - {blockToTime(reservation.endTimeInBlocks)} |{' '}
          {guests} PAX
        </small>
      </span>
      <Box className="space-between">
        <Typography
          variant="text-4"
          block
          className={coloredLogo ? 'text-subdued' : 'text-white'}
          style={{ marginTop: 4 }}
        >
          {isAnotherDay ? toEuropeanDate(date) + ' - ' : ''}
          {time}
          {!!reservation.customData?.['Verspätet um'] && (
            <>
              {' '}
              <small
                style={{
                  padding: 2,
                  borderRadius: 2,
                  background: 'var(--color-warning)',
                  color: 'black',
                }}
              >
                +{reservation.customData?.['Verspätet um']} Min
              </small>
            </>
          )}{' '}
          {showReservationLength
            ? `- ${blockToTime(
                timeToBlock(time) + reservation.reservationLength
              )}`
            : ''}{' '}
          • {guests} Pax
        </Typography>
        <AttrIcons
          customAttr={guest.customAttr ?? []}
          attr={[
            ...(guest?.attr || []),
            guest?.comment || guest?.hostComment ? 'comment' : '',
            !!reservation.ticketId ? 'ticket' : '',
            !!reservation.minimumConsumption ? 'ticket' : '',
          ]}
          fixed={reservation.fixed}
          coloredLogo={coloredLogo}
          feedbackRating={feedbackRating}
          done={!!reservation.done}
          creditCardCustomerId={guest.creditCardCustomerId}
          noShow={!!reservation.noShow}
          noShowFee={reservation.noShowFee}
          addOns={!!addOnOrders.length || !!reservation.hasAddOnOrders}
        />
      </Box>
      <Box className="space-between" style={{ minHeight: 36 }}>
        <Typography
          variant="text-3"
          weight="bold"
          block
          className={
            coloredLogo
              ? hasPayment && !nameStr.startsWith('Wird gerade erstellt')
                ? 'text-minimum-consumption'
                : ''
              : 'text-white'
          }
          style={{
            marginTop: 3,
            whiteSpace:
              nameStr.length <
              (!reservation.noShowConfirmation?.validTill ? 20 : 0)
                ? 'nowrap'
                : 'normal',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {!!experienceVoucher && '🎁 '}
          {currentCustomStatus?.icon ?? ''}
          {nameStr} {nameStr.length === 24 ? '... ' : ' '}
          {!!reservation.noShowConfirmation?.validTill && '(Kreditkarte f.)'}
          {reservation.source === 'phone' && (
            <PermPhoneMsgRounded fontSize="inherit" style={{ marginLeft: 4 }} />
          )}
        </Typography>
        {!isAnotherDay && !isMobile && (
          <ActionButtons
            {...{
              id,
              handleAction,
              coloredLogo,
              currentStatus,
              reservation,
            }}
          />
        )}
      </Box>
      {(!!Shuffle.state || reservationSettings?.showCommentInResaCard) && (
        <Box style={{ marginBottom: 4 }}>
          {!!reservation.guest.comment && (
            <Box>
              <Typography
                variant="text-4"
                block
                weight="bold"
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                Kommentar
              </Typography>
              <Typography
                variant="text-4"
                block
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                {!experimentalFlags?.data?.truncateComments
                  ? reservation.guest.comment
                  : reservation.guest.comment.slice(0, 80)}
              </Typography>
            </Box>
          )}
          {!!reservation.guest.hostComment && (
            <Box>
              <Typography
                variant="text-4"
                block
                weight="bold"
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                Service Kommentar
              </Typography>
              <Typography
                variant="text-4"
                block
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                {!experimentalFlags?.data?.truncateComments
                  ? reservation.guest.hostComment
                  : reservation.guest.hostComment.slice(0, 80)}
              </Typography>
            </Box>
          )}
          {!!reservation.guest.guestComment && (
            <Box>
              <Typography
                variant="text-4"
                block
                weight="bold"
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                Langzeit Kommentar
              </Typography>
              <Typography
                variant="text-4"
                block
                className={coloredLogo ? 'text-subdued' : 'text-white'}
              >
                {!experimentalFlags?.data?.truncateComments
                  ? reservation.guest.guestComment
                  : reservation.guest.guestComment.slice(0, 80)}
              </Typography>
            </Box>
          )}
          {cleanCustomData(
            reservation.customData ?? {},
            !!reservation.additional?.imported
          )
            .filter((x) => !!stringifyCustomDataValue(x[1]))
            .map(([key, value]) => (
              <Box
                style={
                  String(stringifyCustomDataValue(value)).length < 100
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }
                    : {}
                }
              >
                <Typography
                  variant="text-4"
                  block
                  weight="bold"
                  className={coloredLogo ? 'text-subdued' : 'text-white'}
                >
                  {key === 'balance'
                    ? 'Bisher bestellt'
                    : key.replace('_internal', '')}
                </Typography>
                <Typography
                  variant="text-4"
                  block
                  className={coloredLogo ? 'text-subdued' : 'text-white'}
                >
                  {stringifyCustomDataValue(value, key)}
                </Typography>
              </Box>
            ))}
        </Box>
      )}
      <Box className="space-between flex-end">
        <Box style={{ paddingBottom: 4 }} flex>
          {isAnotherDay && (
            <>
              <WarningRounded
                style={
                  coloredLogo
                    ? { color: '#CCC910', fontSize: '14px' }
                    : { color: 'white', fontSize: '14px' }
                }
              />
              <Typography
                variant="text-4"
                className={coloredLogo ? 'text-subdued' : 'text-white'}
                style={{ marginRight: 3 }}
                translation="reservations"
              >
                Wrong Date •
              </Typography>
            </>
          )}
          {!!reservation.stammtischId && (
            <Typography
              variant="text-4"
              className="text-white"
              style={
                coloredLogo
                  ? { marginRight: 3, color: 'var(--color-tertiary)' }
                  : { marginRight: 3 }
              }
            >
              Stammtisch •
            </Typography>
          )}
          {!!reservation.ticketId && (
            <Typography
              variant="text-4"
              className="text-white"
              style={
                coloredLogo
                  ? { marginRight: 3, color: 'var(--color-tertiary)' }
                  : { marginRight: 3 }
              }
            >
              Ticket •
            </Typography>
          )}
          {!!reservation.minimumConsumption && (
            <Typography
              variant="text-4"
              className="text-white"
              style={
                coloredLogo
                  ? { marginRight: 3, color: 'var(--color-secondary)' }
                  : { marginRight: 3 }
              }
            >
              Min. Verzehr •
            </Typography>
          )}
          {!hideOccassion && (
            <Typography
              variant="text-4"
              className={coloredLogo ? 'text-subdued' : 'text-white'}
              style={{ marginRight: 3 }}
            >
              {occassionString.slice(0, 25)} •
            </Typography>
          )}
          {!!experienceVoucher && (
            <Typography
              variant="text-4"
              className="text-white"
              style={
                coloredLogo
                  ? { marginRight: 3, color: 'var(--color-tertiary)' }
                  : { marginRight: 3 }
              }
            >
              Gutschein •
            </Typography>
          )}
          <Typography
            variant="text-4"
            className={coloredLogo ? 'text-subdued' : 'text-white'}
            translation="reservations"
            style={
              tables?.length && hideOccassion
                ? {
                    fontSize: '0.9rem',
                    fontWeight: 'bold',
                  }
                : {}
            }
          >
            {currentStatus === 'failed'
              ? 'Canceled'
              : (experimentalFlags?.data?.truncateTableNames
                  ? `${tables?.[0]}${tables?.[1] ? '+' : ''}`
                  : tables?.join(', ')) || 'No Tables'}
          </Typography>
        </Box>

        <Typography
          variant="text-5"
          style={{
            color: coloredLogo ? 'var(--color-tertiary)' : 'white',
          }}
        >
          {reservation.position && reservation.position + '.'}
        </Typography>
      </Box>

      <Warning
        id={id}
        hide
        style={{
          padding: 0,
          paddingTop: 8,
        }}
      />

      {!!additionalButtons?.length && (
        <Box className="flex" style={{ margin: '4px 0', gap: 8 }}>
          {additionalButtons?.map((button, index) => (
            <Button
              key={index}
              className={classHelper([
                'res-additional-button',
                coloredLogo && 'uncolored',
              ])}
              onClick={async (e) => {
                e.stopPropagation();
                if (loading) return;
                setloading(true);
                await handleAction(id, button.type);
                setloading(false);
              }}
              style={{
                border: coloredLogo
                  ? '2px solid var(--color-border)'
                  : '2px solid #ffffff',
                background: 'transparent',
                color: coloredLogo
                  ? 'var(--color-text-sub)'
                  : 'rgba(255,255,255,0.8)',
              }}
              loading={loading}
            >
              {button.label}
            </Button>
          ))}
        </Box>
      )}

      {hasWarnings && <WarningBar />}
    </Box>
  );
};

export default ReservationCard;
