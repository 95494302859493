import React, { useContext, useMemo, useState } from "react";
import Box from "Components/Atoms/Box";
import { classHelper, dateHelper, noop, toEuropeanDate } from "utils/helper";
import { RestaurantContext } from "Contexts/RestaurantContext";
import MobileNavSelectRestaurant from "./Components/MobileNavSelectRestaurant";
import { ArrowBack, Notifications, Settings } from "Components/Atoms/Icons";
import IconButton from "Components/Atoms/IconButton";
import { UserData } from "utils/types";
import MobileModal from "App/Mobile/Components/MobileModal";
import SettingsDropdownOptions from "App/Dashboard/Components/SettingsDropdown/Components/SettingsDropdownOptions";
import { ThemeContext } from "Contexts/ThemeContext";
import { LanguageContext } from "Contexts/LanguageContext";
import { AuthContext } from "Contexts/AuthContext";
import { useHistory } from "react-router";
import Typography from "Components/Atoms/Typography";
import Calendar from "Components/Molecules/Calendar";
import Button from "Components/Atoms/Button";

import "./styles.scss";
import { CircularProgress } from "@material-ui/core";
import useNotifications from "CustomHooks/useNotification";
import NotificationCard from "Components/Molecules/NotificationCard";
import EmptyState from "Components/Molecules/PieChartCard/Components/EmptyState";

export type MobileNavBarProps = {
  className?: string;
  user: null | UserData;
  title?: string;
  date?: string;
  onDateChange?: (newDate: string) => void;
  goBack?: boolean;
};

const MobileNavBar = ({
  className = "",
  user,
  title,
  date,
  goBack = false,
  onDateChange,
}: MobileNavBarProps) => {
  const [isMobileSettingsModalOpen, setIsMobileSettingsModalOpen] =
    useState<boolean>(false);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState<boolean>(false);
  const [changeDateModal, setChangeDateModal] = useState<boolean>(false);

  const location = window.location.pathname;
  const today = new Date();

  let tomorrowBtnValue = () => {
    const tomorrow = new Date(today);
    const timestamp = tomorrow.setDate(tomorrow.getDate() + 1);
    const toDate = new Date(timestamp);

    return dateHelper(toDate);
  };

  const classNames = classHelper([
    "mobile-navbar",
    "space-between",
    "corner",
    className,
  ]);
  const userRestaurants = user?.restaurants || [];

  const { darkMode, setdarkMode } = useContext(ThemeContext);
  const { language, changeLanguage, LANGUAGES } = useContext(LanguageContext);
  const { signOutUser, restaurantId } = useContext(AuthContext);
  const { switchRestaurant } = useContext(RestaurantContext);
  const history = useHistory();

  const {
    notifications,
    notificationsLoading,
    getAllNotifications,
    markAllNotificationsAsSeen,
    markNotificationAsSeen,
    deleteNotification,
  } = useNotifications(restaurantId ?? "");

  const handleRestaurantChange = (rId: string) => {
    switchRestaurant(rId);
    history?.push(`/${rId}`);
  };

  const handleSettingsClick = (rId: string) => {
    switchRestaurant(rId);
    history?.push(`/${rId}/settings`);
  };

  if (!location || !restaurantId) {
    return (
      <>
        <CircularProgress />
      </>
    );
  }

  if (location !== "/" && location !== `/${restaurantId}`) {
    return (
      <Box className={classNames} elevation={2} padding="md">
        <Box flex>
          {location.includes("/reservations") ||
            (goBack && (
              <IconButton
                size="small"
                color="subdued"
                onClick={() => history.push("/")}
              >
                <ArrowBack />
              </IconButton>
            ))}
          <Typography block variant="h4" style={{ marginLeft: 8 }}>
            {title}
          </Typography>
        </Box>
        {!!onDateChange && (
          <Box flex style={{ alignItems: "center", width: "max-content" }}>
            <svg height="12px" width="12px" viewBox="0 0 24 24">
              <circle
                cx={12}
                cy={12}
                r={6}
                stroke="var(--color-primary)"
                fill={
                  date === dateHelper() ? "var(--color-primary)" : "transparent"
                }
                strokeWidth={2}
              />
            </svg>
            <Typography
              variant="text-2"
              color="subdued"
              style={{ marginLeft: 8 }}
              onClick={() => !!onDateChange && setChangeDateModal(true)}
            >
              {toEuropeanDate(date || dateHelper(), true)}
            </Typography>
            {!!onDateChange && (
              <MobileModal
                title="Select Date"
                titleTranslation="dashboard"
                isOpen={changeDateModal}
                onClose={() => setChangeDateModal(false)}
              >
                <Box flex className="center">
                  <Calendar
                    value={date || dateHelper()}
                    mobileVersion
                    width="inherit"
                    onChange={onDateChange}
                  />
                </Box>
                <Box flex style={{ marginTop: 16 }}>
                  <Button
                    type="button"
                    variant="default"
                    onClick={() =>
                      history.push(
                        `/${restaurantId}/reservations/${dateHelper()}/tablePlan`
                      )
                    }
                    style={{ border: "solid 1px var(--color-border)" }}
                  >
                    Today
                  </Button>
                  <Button
                    variant="default"
                    type="button"
                    style={{
                      marginLeft: 8,
                      border: "solid 1px var(--color-border)",
                    }}
                    onClick={() =>
                      history.push(
                        `/${restaurantId}/reservations/${tomorrowBtnValue()}/tablePlan`
                      )
                    }
                  >
                    Tomorrow
                  </Button>
                </Box>
              </MobileModal>
            )}
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box className={classNames} elevation={2} padding="md" flex>
      <MobileNavSelectRestaurant
        restaurantId={restaurantId}
        restaurants={userRestaurants}
        onRestaurantChange={handleRestaurantChange}
        onSettingsClick={handleSettingsClick}
      />

      <Box flex width="auto">
        <IconButton
          onClick={() => setIsNotificationsModalOpen(true)}
          style={{ marginRight: "16px" }}
        >
          <Notifications className="icon" />
        </IconButton>

        <IconButton onClick={() => setIsMobileSettingsModalOpen(true)}>
          <Settings className="icon" />
        </IconButton>
      </Box>

      {user !== null && (
        <MobileModal
          isOpen={isMobileSettingsModalOpen}
          onClose={() => setIsMobileSettingsModalOpen(false)}
          title="Settings"
          titleTranslation="settings"
        >
          <Box flex style={{ justifyContent: "center", width: "100%" }}>
            <SettingsDropdownOptions
              {...{
                user,
                darkMode,
                language,
                setdarkMode,
                onLanguageChange: changeLanguage,
                languages: LANGUAGES,
                onLogout: signOutUser,
                goToSettings: () =>
                  history?.push(
                    restaurantId ? `/${restaurantId}/settings` : "/"
                  ),
              }}
            />
          </Box>
        </MobileModal>
      )}
      <MobileModal
        isOpen={isNotificationsModalOpen}
        onClose={() => setIsNotificationsModalOpen(false)}
        title="Notifications"
        titleTranslation="settings"
      >
        <Box className="mobile-notifications-container">
          {notificationsLoading && (
            <Box flex className="center" style={{ height: 50 }}>
              <CircularProgress />
            </Box>
          )}
          {!notificationsLoading && !!notifications.length && (
            <Box style={{ overflow: "scroll" }}>
              {notifications.map((n, i) => (
                <NotificationCard
                  key={n.id}
                  notification={n}
                  onClick={() => {
                    if (n.link) history.push(n.link);
                  }}
                  onClose={(id) => {
                    // onHideCard(id);
                    deleteNotification(id);
                  }}
                  lastOne={i === notifications.length - 1}
                />
              ))}
            </Box>
          )}
          {notifications.length === 0 && (
            <Box flex className="center" style={{ height: 50 }}>
              <Typography
                variant="h5"
                translation="settings"
                style={{ marginRight: 12 }}
              >
                No new notifications.
              </Typography>
            </Box>
          )}
        </Box>
      </MobileModal>
    </Box>
  );
};

export default MobileNavBar;
