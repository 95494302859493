import IconButton from 'Components/Atoms/IconButton';
import Box from 'Components/Atoms/Box';
import Typography, { Colors } from 'Components/Atoms/Typography';
import React, { useMemo } from 'react';
import { classHelper, wDayHelper } from 'utils/helper';
import { Day as DayProps, Modify } from 'utils/types';
import {
  Lock,
  LockOpen,
  PartlyLocked,
  TerraceClosed,
  TerraceOpen,
} from 'Components/Atoms/Icons';
import './styles.scss';
import Tooltip from 'Components/Atoms/Tooltip';
import { ShiftType } from 'types/shifts';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MobileReservationModal from 'App/Dashboard/Components/MobileReservationModal';
import useAlert from 'CustomHooks/useAlert';
import useProtectedAction from 'utils/useProtectedAction';
import ReservationOptimization from 'Components/Atoms/ReservationOptimization';

function expressWhat(locked: 'locked' | 'open' | 'partly', date: string) {
  switch (locked) {
    case 'locked':
      return `Unlock ${date}`;
    case 'open':
      return `Lock ${date}`;
    default:
      return `Lock / Unlock ${date}`;
  }
}

const status = (num: number) => {
  if (isNaN(num) || num === 1) return 'locked';
  if (num === 0) return 'open';
  return 'partly';
};

const Day = ({
  date,
  wDay,
  open,
  shifts,
  showTerrace,
  average,
  reservations,
  weather,
  scoreDiff = 1,
  handleAction,
  isReservationModalOpen,
  openReservationModal,
  closeReservationModal,
  terraceOpen,
  update,
  dateToModify,
  addOnStats,
  hasAddOns,
  showOptimization,
}: Modify<
  DayProps,
  {
    handleAction: (
      date: string,
      target: 'close' | 'terrace' | 'optimize',
      open?: boolean
    ) => void;
    openReservationModal?: () => void;
    isReservationModalOpen?: boolean;
    update?: () => void;
    closeReservationModal?: () => void;
    dateToModify?: string;
    hasAddOns?: boolean;
    showOptimization?: boolean;
  }
>) => {
  const wDayString = wDayHelper(wDay);
  const isMobile = useMediaQuery('(max-width: 450px)');

  const protectedAction = useProtectedAction(
    'calendar.blockReservations.write'
  );

  const locked: 'locked' | 'open' | 'partly' = useMemo(() => {
    let xShifts = shifts.filter(
      (x) => !x.disabled && x.type === ShiftType.RESERVATION && x.active
    );

    if (
      xShifts.every(
        (x) =>
          x.overwrite?.blockReservations ||
          (x.overwrite?.maxGuests ?? x.additional?.maxGuests) === 0
      )
    ) {
      return 'locked';
    } else if (
      xShifts.some(
        (x) =>
          x.overwrite?.blockReservations ||
          (x.overwrite?.maxGuests ?? x.additional?.maxGuests) === 0
      )
    ) {
      return 'partly';
    }

    return 'open';
  }, [shifts]);
  // const terraceOpen = false; // @TODO;

  const colorHelper = (color: Colors) => {
    if (!open) {
      return 'disabled';
    } else {
      return color;
    }
  };

  const classNames = classHelper([
    !isMobile && 'flex-column',
    'total-reservation-day',
    isMobile && 'mobile-design',
  ]);

  console.log({ date, reservations });

  return (
    <Box className={classNames}>
      {showOptimization && (
        <ReservationOptimization
          scoreDiff={scoreDiff}
          onClick={() => handleAction(date, 'optimize')}
        />
      )}
      <Typography
        variant="text-4"
        color="subdued"
        textAlign={isMobile ? 'left' : 'center'}
        className="mg-bt-sm"
      >
        {wDayString}
      </Typography>
      {!!weather?.weather && (
        <img
          src={`https://www.weatherbit.io/static/img/icons/${weather?.weather?.icon}.png`}
          alt={weather?.weather?.description || ''}
          width={24}
          height={24}
        />
      )}
      <Typography
        variant="text-4"
        color={colorHelper('subdued')}
        textAlign="center"
        className="mg-bt-sm"
      >
        {typeof weather?.temp === 'number'
          ? `${Math.round(weather.temp)}°`
          : '-'}
      </Typography>
      <Box
        background
        padding="sm"
        className="flex-column mg-bt-sm"
        outline
        style={{ alignItems: isMobile ? 'flex-start' : 'center' }}
      >
        <Typography
          variant={open ? 'text-3' : 'text-4'}
          color={colorHelper('textPrimary')}
          textAlign={isMobile ? 'left' : 'center'}
          translationKey={!open ? 'common_closedDay' : undefined}
        >
          {open ? reservations : 'Closed'}
        </Typography>
        <Typography
          variant="text-4"
          color={colorHelper('subdued')}
          textAlign="center"
          style={{ marginBottom: 4, whiteSpace: 'nowrap' }}
        >
          {average === null ? '-' : `Ø ${average}`}
        </Typography>
        <Box flex className="space-around" style={{ width: '130%' }}>
          <Tooltip content={'Open or Close Shifts for Reservations'}>
            <IconButton
              disabled={!open}
              size="small"
              onClick={() => {
                protectedAction('chefWaiter', expressWhat(locked, date), () => {
                  if (openReservationModal) openReservationModal();
                  handleAction(date, 'close');
                });
              }}
              color="primary"
              aria-label="Lock Day"
            >
              {locked === 'locked' && <Lock />}
              {locked === 'open' && <LockOpen />}
              {locked === 'partly' && <PartlyLocked />}
            </IconButton>
          </Tooltip>
          {showTerrace && (
            <Tooltip content={terraceOpen ? 'Close Terrace' : 'Open Terrace'}>
              <IconButton
                disabled={!open}
                size="small"
                onClick={() => {
                  protectedAction(
                    'chefWaiter',
                    terraceOpen
                      ? `Terasse geschlossen ${date}`
                      : `Terasse geöffnet ${date}`,
                    () => {
                      handleAction(date, 'terrace', terraceOpen);
                    }
                  );
                }}
                color="tertiary"
              >
                {!!terraceOpen ? <TerraceOpen /> : <TerraceClosed />}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      {!!addOnStats?.sales && (
        <Typography
          variant="text-5"
          textAlign="center"
          component="p"
          color="secondary"
          style={{
            background: 'var(--color-secondary)',
            color: 'white',
            padding: '2px 4px',
            borderRadius: 2,
          }}
        >
          {addOnStats.total
            .toFixed(addOnStats.total < 100 ? 2 : 0)
            .replace('.', ',') + '€'}{' '}
          ({addOnStats.sales})
          {/* <br />
          <small style={{ whiteSpace: 'nowrap' }}>
            
          </small> */}
        </Typography>
      )}
      {hasAddOns && !addOnStats?.sales && (
        <Typography
          variant="text-5"
          textAlign="center"
          component="p"
          color="secondary"
          style={{
            background: 'var(--color-secondary)',
            color: 'white',
            padding: '2px 4px',
            borderRadius: 2,
            opacity: 0.5,
          }}
        >
          0,00€ (0)
          {/* <br />
          <small style={{ whiteSpace: 'nowrap' }}>(0 Sales)</small> */}
        </Typography>
      )}
      <MobileReservationModal
        isReservationModalOpen={isReservationModalOpen ?? false}
        update={() => update && update()}
        dateToModify={dateToModify ?? ''}
        closeReservationModal={() => {
          protectedAction('chefWaiter', '', () => {
            if (closeReservationModal) {
              closeReservationModal();
            }
          });
        }}
      />
    </Box>
  );
};

export default Day;
