import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import Typography, { Colors } from 'Components/Atoms/Typography';
import {
  Lock,
  LockOpen,
  PartlyLocked,
  TerraceClosed,
  TerraceOpen,
} from 'Components/Atoms/Icons';
import { LanguageContext } from 'Contexts/LanguageContext';
import React, { useContext, useMemo } from 'react';
import { Day } from 'utils/types';
import './styles.scss';
import Tooltip from 'Components/Atoms/Tooltip';
import { ShiftType } from 'types/shifts';
import useProtectedAction from 'utils/useProtectedAction';
import usePinAuth from 'CustomHooks/usePinAuth';
import ReservationOptimization from 'Components/Atoms/ReservationOptimization';
import { dateHelper, toEuropeanDate } from 'utils/helper';

function expressWhat(locked: 'locked' | 'open' | 'partly', date: string) {
  switch (locked) {
    case 'locked':
      return `Unlock ${date}`;
    case 'open':
      return `Lock ${date}`;
    default:
      return `Lock / Unlock ${date}`;
  }
}

type TodayProps = {
  today: Day;
  handleAction: (
    date: string,
    target: 'close' | 'terrace' | 'optimize',
    open?: boolean
  ) => void;
  updatedAt?: number;
  totalReservations?: number;
  showOptimization?: boolean;
};

const status = (num: number) => {
  if (isNaN(num) || num === 1) return 'locked';
  if (num === 0) return 'open';
  return 'partly';
};

const Today = ({
  today,
  handleAction,
  updatedAt,
  totalReservations,
  showOptimization,
}: TodayProps) => {
  const { dayjs } = useContext(LanguageContext);

  const protectedAction = useProtectedAction(
    'calendar.blockReservations.write'
  );

  const locked: 'locked' | 'open' | 'partly' = useMemo(() => {
    let shifts = today.shifts.filter(
      (x) => !x.disabled && x.type === ShiftType.RESERVATION && x.active
    );

    if (
      shifts.every(
        (x) =>
          x.overwrite?.blockReservations ||
          (x.overwrite?.maxGuests ?? x.additional?.maxGuests) === 0
      )
    ) {
      return 'locked';
    } else if (
      shifts.some(
        (x) =>
          x.overwrite?.blockReservations ||
          (x.overwrite?.maxGuests ?? x.additional?.maxGuests) === 0
      )
    ) {
      return 'partly';
    }

    return 'open';
  }, [today.shifts]);

  const colorHelper = (color: Colors) => {
    if (!today.open) {
      return 'disabled';
    } else {
      return color;
    }
  };

  const updatedAtStr = !!updatedAt ? dayjs(updatedAt).fromNow() : '';

  const resaCount = totalReservations ?? today.reservations;

  const { authenticate } = usePinAuth();

  return (
    <Box padding="md" className="total-res-today">
      <Box flex className="flex-start">
        <Typography
          variant="text-3"
          color="subdued"
          style={{ marginRight: 10 }}
        >
          {toEuropeanDate(dateHelper(), true)} -{' '}
          {new Date().toLocaleTimeString('de-DE').slice(0, 5)}
        </Typography>

        {/* {updatedAt && (
          <Typography
            variant="text-4"
            color="subdued"
            translationKey="dashboard_updated"
          >
            Updated {{ updatedAtStr }}
          </Typography>
        )} */}
      </Box>
      <Box flex className="space-between">
        <Box flex>
          {!!resaCount && (
            <Typography variant="text-3" style={{ marginRight: 8 }}>
              Reservations
            </Typography>
          )}
          <Typography
            variant={!today.open || !resaCount ? 'h5' : 'h3'}
            color="primary"
          >
            {today?.open
              ? resaCount || 'No Reservations'
              : 'Restaurant is closed'}
          </Typography>
          {showOptimization && (
            <ReservationOptimization
              style={{ marginLeft: 10 }}
              scoreDiff={today?.scoreDiff ?? 1}
              onClick={() => handleAction(today.date, 'optimize')}
            />
          )}
          {!!today?.addOnStats?.sales && (
            <Typography
              variant="text-5"
              textAlign="center"
              component="p"
              color="secondary"
              style={{
                background: 'var(--color-secondary)',
                color: 'white',
                padding: '2px 4px',
                borderRadius: 2,
                marginLeft: 12,
                transform: 'translateY(4px)',
              }}
            >
              {today.addOnStats.total.toFixed(2).replace('.', ',') + '€'}
              <br />
              <small style={{ whiteSpace: 'nowrap' }}>
                ({today.addOnStats.sales} Sales)
              </small>
            </Typography>
          )}
        </Box>
        <Box flex className="right">
          {!!today?.weather && (
            <>
              <Typography variant="text-3">
                {typeof today?.weather?.temp === 'number'
                  ? Math.round(today.weather.temp)
                  : '-'}
                °
              </Typography>
              <img
                src={`https://www.weatherbit.io/static/img/icons/${today.weather.weather.icon}.png`}
                alt={today.weather.weather.description || ''}
                width={24}
                height={24}
              />
            </>
          )}
          <Tooltip content="Open or Close Shifts for Reservations">
            <IconButton
              aria-label="Lock Today"
              disabled={!today.open}
              size="small"
              onClick={() => {
                protectedAction(
                  'chefWaiter',
                  expressWhat(locked, today.date),
                  () => {
                    handleAction(today.date, 'close');
                  }
                );
              }}
              color={colorHelper('primary')}
            >
              {locked === 'locked' && <Lock />}
              {locked === 'open' && <LockOpen />}
              {locked === 'partly' && <PartlyLocked />}
            </IconButton>
          </Tooltip>
          {today.showTerrace && (
            <Tooltip
              content={today.terraceOpen ? 'Close Terrace' : 'Open Terrace'}
            >
              <IconButton
                disabled={!today.open}
                size="small"
                onClick={() => {
                  protectedAction(
                    'chefWaiter',
                    today.terraceOpen
                      ? `Terasse geschlossen ${today.date}`
                      : `Terasse geöffnet ${today.date}`,
                    () => {
                      handleAction(today.date, 'terrace', today.terraceOpen);
                    }
                  );
                }}
                color={colorHelper('tertiary')}
              >
                {!!today.terraceOpen ? <TerraceOpen /> : <TerraceClosed />}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
      <Box flex className="flex-start">
        <Typography
          variant="text-4"
          color="subdued"
          translationKey="dashboard_average"
        >
          Average: {{ avg: today.average === null ? '-' : today.average }}
        </Typography>

        {/* {updatedAt && (
          <Typography
            variant="text-4"
            color="subdued"
            translationKey="dashboard_updated"
          >
            Updated {{ updatedAtStr }}
          </Typography>
        )} */}
      </Box>
    </Box>
  );
};

export default Today;
