import Box from 'Components/Atoms/Box';
import IconButton from 'Components/Atoms/IconButton';
import { Delete, DragIndicator } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import React from 'react';
import { noop } from 'utils/helper';
import { CircularProgress } from '@material-ui/core';

type ImageFieldMultipleProps = {
  imageArray: { id: string; url: string; originalName: string }[] | null;
  setimageArray: React.Dispatch<
    React.SetStateAction<
      { id: string; url: string; originalName: string }[] | null
    >
  >;
  onChange: (val: null | string | (string | null)[]) => void;
  type?: 'id' | 'url';
  loading: boolean;
};

type ImageRowProps = {
  id: string | null;
  index: number;
  url: string;
  originalName: string;
  deleteOne: (id: string) => void;
  hideName?: boolean;
};

const ImageRow = ({
  id,
  index,
  url,
  originalName,
  deleteOne,
  hideName,
}: ImageRowProps) => {
  return (
    <Draggable draggableId={id || ''} index={index}>
      {(provided) => (
        <div {...provided.draggableProps} ref={provided.innerRef}>
          <Box id={id || ''} className="space-between image-row">
            <Box flex>
              <span {...provided.dragHandleProps} className="drag-icon">
                <DragIndicator className="drag-indicator" />
              </span>
              <Box
                className={
                  hideName
                    ? 'center multiple-image-medium'
                    : 'center multiple-image-small'
                }
                style={{ backgroundImage: `url(${url})` }}
              ></Box>
              {!hideName && (
                <Typography variant="text-3">{originalName}</Typography>
              )}
            </Box>
            <IconButton
              size="small"
              color="subdued"
              className="delete-btn"
              onClick={() => deleteOne(id || '')}
            >
              <Delete />
            </IconButton>
          </Box>
        </div>
      )}
    </Draggable>
  );
};

const ImageFieldMultiple = ({
  imageArray,
  setimageArray,
  onChange = noop,
  type = 'id',
  loading = false,
}: ImageFieldMultipleProps) => {
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      // No Change
      return;
    }

    if (result.type === 'images' && !!imageArray) {
      const answer = Array.from(imageArray);
      const [removed] = answer.splice(result.source.index, 1);
      answer.splice(result.destination.index, 0, removed);

      const newArray = answer.map((r, position) =>
        typeof r === 'object' ? { ...r, position } : r
      );
      setimageArray(newArray);
      onChange(newArray.map((img) => (type === 'id' ? img.id : img.url)));
    }
  };

  const deleteOne = (id: string) => {
    let newArray = imageArray?.filter((image) => image.id !== id) || [];
    setimageArray(newArray);
    onChange(newArray.map((img) => (type === 'id' ? img.id : img.url)));
  };

  return (
    <Box className="image-multiple-container">
      {loading ? (
        <Box className="center">
          <CircularProgress />
        </Box>
      ) : !imageArray ? (
        <Typography variant="text-3" color="subdued">
          No Data
        </Typography>
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="images" type="images">
            {(provided) => (
              <div ref={provided.innerRef}>
                {imageArray.map((img, index) => (
                  <ImageRow
                    {...{ onChange, deleteOne, index, ...img }}
                    key={img.id}
                    hideName={type === 'url'}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Box>
  );
};

export default ImageFieldMultiple;
