import TextEditor from 'Components/Molecules/TextEditor';
import React, { useState, useEffect, useMemo } from 'react';
import { BaseElement, BaseText, Descendant, Node } from 'slate';
// import {
//   serializeHTMLFromNodes,
//   deserializeHTMLToDocumentFragment,
//   createEditorPlugins,
// } from "@udecode/plate";
import { deserialize } from './deserialize';
//const Html = require('slate-html-serializer')
import { Text } from 'slate';
import escapeHtml from 'escape-html';
import { classHelper, noop } from 'utils/helper';
import './styles.scss';
import useTextHelper from 'CustomHooks/useTextHelper';
import LiveHelp from '@material-ui/icons/LiveHelp';
import { Translate } from '@material-ui/icons';

const serialize = (node: any) => {
  if (Text.isText(node) as any) {
    let string = escapeHtml(node.text);
    console.log(node, string);
    if (node.italic) {
      string = `<i>${string}</i>`;
    }
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    return string;
  }

  const children = node.children.map((n: any) => serialize(n)).join('');

  switch (node.type) {
    case 'heading-two':
      return `<h2>${children}</h2>`;
    case 'heading-one':
      return `<h1>${children}</h1>`;
    case 'quote':
      return `<blockquote><p>${children}</p></blockquote>`;
    case 'paragraph':
      return `<p>${children}</p>`;
    case 'link':
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    case 'bulleted-list':
      return `<ul>${children}</ul>`;
    case 'list-item':
      return `<li>${children}</li>`;
    case 'check-list-item':
      return `<p>${
        node.checked
          ? '<input type="checkbox" checked />'
          : '<input type="checkbox" />'
      } ${children}</p>`;
    default:
      return children;
  }
};

export type RichTextFieldProps = {
  value: string;
  templateGroupId?: string;
  onChange: (val: string) => void;
  onBlur: (val: string) => void;
  disabled?: boolean;
  id?: string;
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  onTranslate?: (() => void) | false;
};

// const editor = createEditorPlugins();

const htmlToSlate = (html: string = '<p></p>') => {
  const parsed = new DOMParser().parseFromString(html, 'text/html').body;
  return deserialize(parsed) as Descendant[];
};

export const slateToHtml = (nodes: Node[]) => {
  return nodes.map((n) => serialize(n)).join('');
};

const RichTextField: React.FC<RichTextFieldProps> = ({
  value,
  onChange,
  onBlur,
  disabled = false,
  id,
  style,
  className,
  onClick = noop,
  templateGroupId,
  onTranslate,
}) => {
  // const [internalValue, setInternalValue] = useState<Descendant[]>();

  const internalValue = useMemo(() => {
    try {
      return htmlToSlate(value);
    } catch (error) {
      console.warn(error);
      return [];
    }
  }, [value]);

  const handleChange = (val: Descendant[]) => {
    if (disabled || JSON.stringify(val) === JSON.stringify(internalValue))
      return;
    try {
      const html = slateToHtml(val);

      // console.log({
      //   slateToHtml: html,
      //   val,
      //   change: true,
      // });
      onChange(html);
    } catch (error) {
      console.warn(error);
    }
  };

  const handleBlur = (val: Descendant[]) => {
    if (disabled || JSON.stringify(val) === JSON.stringify(internalValue))
      return;

    try {
      const html = slateToHtml(val);

      // console.log({
      //   slateToHtml: html,
      //   val,
      // });
      onBlur(html);
    } catch (error) {
      console.warn('slateToHtml', error);
    }
  };

  const { initTextHelper } = useTextHelper();

  return (
    <div id={id} style={style} className={classHelper(['richText', className])}>
      <TextEditor
        value={internalValue}
        onChange={handleChange}
        onBlur={handleBlur}
        onlyLimitedValues
        onClick={onClick}
        extraButtons={[
          ...(!!templateGroupId
            ? [
                {
                  icon: () => <LiveHelp />,
                  title: 'Texte generieren',
                  onClick: () => {
                    initTextHelper({
                      groupId: templateGroupId,
                      onSubmit: (result) => onBlur(result),
                      html: true,
                    });
                  },
                },
              ]
            : []),
          ...(onTranslate
            ? [
                {
                  icon: () => <Translate />,
                  title: 'Aus dem deutschen Übersetzen',
                  onClick: onTranslate,
                },
              ]
            : []),
        ]}
      />
    </div>
  );
};

export default RichTextField;
