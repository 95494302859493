import React, { useEffect, useMemo, useContext } from 'react';
import Box from 'Components/Atoms/Box';
import './styles.scss';
import {
  Add,
  AssignmentTurnedInRounded,
  Close,
  Redeem,
  Warning,
} from 'Components/Atoms/Icons';
import Ticket from '@material-ui/icons/LocalActivity';
import Button from 'Components/Atoms/Button';
import {
  classHelper,
  dateHelper,
  noop,
  timeHelper,
  timeToBlock,
} from 'utils/helper';
import NavBar from 'Components/Organisms/NavBar';
import DateDropdown from 'Components/Organisms/DateDropdown';
import FeatureNavigation from 'Components/Molecules/FeatureNavigation';
import { paths } from 'App/ReservationBook/shared';
import LeftSideBar from 'App/ReservationBook/Components/LeftSideBar';
import ReservationSideBar from 'App/ReservationBook/Components/ReservationSideBar';
import useReservationSideBar from 'CustomHooks/useReservationSideBar';
import TableSideBar from 'App/ReservationBook/Components/TableSideBar';
import useTableSideBar from 'CustomHooks/useTableSideBar';
import EditReservationSidebar from 'App/ReservationBook/Components/EditReservationSidebar';
import NewReservationSidebar from 'App/ReservationBook/Components/NewReservationSidebar';
import FloorPlan from 'App/ReservationBook/Components/FloorPlan';
import Dropdown, { OptionElementType } from 'Components/Molecules/Dropdown';
import { ReservationContext } from 'Contexts/ReservationContext';
import { AuthContext } from 'Contexts/AuthContext';
import SwitchReservationModal from 'App/ReservationBook/Components/SwitchReservationModal';
import IconButton from 'Components/Atoms/IconButton';
import useAlert from 'CustomHooks/useAlert';
import { CurrentStatus } from 'types/reservations';
import { db } from 'utils/firebase';
import Typography from 'Components/Atoms/Typography';
import { ListItem } from '@material-ui/core';
import Badge from 'Components/Atoms/Badge';
import EventModal from 'App/ReservationBook/Components/EventModal';
import ShuffleModeIcon from 'Components/Atoms/Icons/ShuffleModeIcon';
import ShuffleButton from 'App/ReservationBook/Components/ShuffleButton';
import MailboxAlert from 'App/ReservationBook/Components/MailboxAlert';
import WaitinglistSidebar from 'App/ReservationBook/Components/WaitinglistSidebar';
import DragAndDropProvider from 'CustomHooks/useDragAndDrop';
import ExperimentalFlags from 'App/ReservationBook/Components/ExperimentalFlags';
import { RequestStatus } from 'gastronaut-shared/types/helper/reservations';
import QuickModal from 'App/Voucher/Components/QuickModal';
import VoucherIcon from 'Components/Atoms/Icons/VoucherIcon';

export type TablePlanProps = {
  date: string;
  restaurantId: string;
  onDateChange: (newDate: string) => void;
};

function OptionElement({
  onClick,
  selected,
  id,
  label,
  justifyContent,
}: OptionElementType<string | null>) {
  return (
    <ListItem
      key={String(id) || 'null'}
      style={{ justifyContent }}
      onClick={onClick}
      selected={selected}
      button
    >
      <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
    </ListItem>
  );
}

/*
    CHECKLIST:
    [x] - New Reservation
    [x] - Shifts
    [x] - Settings
    [x] - TimeSlots
    [x] - Sorting Reservations
    [x] - GTable Blocked
    [x] - Guest Panel Data has to be loaded every time.
    [x] - GuestDropdown
    [x] - Handle Walkin
    [x] - Pick Most Meaningfull Shift
    [x] - Go back
    [x] - Current Reservation doesn't update when Reservation changes
    [x] - Reservation Actions
    [x] - Loading
    [x] - Colors
    [x] - FloorPlan Message
    [x] - Show Time for walkin even if no time is available
    [x] - Block GTable
    [x] - Check Id API
    [x] - handleSubmit
    [x] - Space Switch Alignment
    [x] - Translations
    [x] - Toast UNDO
    [ ] - Data for Calendar
    [ ] - Switch Reservations
    [ ] - Warnings Modal
    [ ] - FloorPlan Walls & Decoration
    [ ] - ReservationLength for Walkins
*/

const TablePlan = ({
  date,
  onDateChange = noop,
  restaurantId,
}: TablePlanProps) => {
  const { uid } = useContext(AuthContext);
  const alert = useAlert();

  const {
    editReservation,
    hasPendingLargeGroupRequests,
    setdate,
    seteditReservation,
    reservations,
    filteredReservations,
    currentTable,
    setcurrentTable,
    currentReservation,
    setcurrentReservation,
    currentShift: currentShiftId,
    setCurrentShift,
    handleTableClick,
    handleAction,
    handleNewReservation,
    handleSubmit,
    floorPlanProps,
    shifts,
    endOfShift,
    currentTime,
    occassions = [],
    settings,
    reservationLoading,
    warnings,
    switchReservationState,
    reservationSettings,
    Shuffle,
    hideLiveResas,
    toggleHideLiveResas,
    currentWaitinglistEntry,
    setcurrentWaitinglistEntry,
    showWarnings,
    setshowWarnings,
  } = useContext(ReservationContext);

  const reservationProps = useReservationSideBar(
    currentReservation,
    reservations
  );

  useEffect(() => {
    if (document.location.search) {
      let query = new URLSearchParams(document.location.search);
      let reservationId = query.get('reservationId');

      if (reservationId) {
        setcurrentReservation(reservationId);
      }

      if (query.get('waitinglistId')) {
        setcurrentWaitinglistEntry(query.get('waitinglistId'));
      }

      let newReservation = query.get('newReservation');

      if (newReservation) {
        handleNewReservation(date);
      }
    }
  }, [document.location.search]);

  const tableProps = useTableSideBar(
    currentTable,
    floorPlanProps.floorPlan?.tables || [],
    reservations,
    currentShiftId,
    shifts,
    date,
    restaurantId
  );

  const { shiftOptions, multipleFloorPlans } = useMemo(() => {
    const floorPlans = shifts?.length
      ? Array.from(
          new Set(
            shifts.map(
              (s) =>
                s?.additional?.floorPlan ??
                reservationSettings?.standardFloorPlan ??
                null
            )
          )
        )
      : [];

    const multipleFloorPlans = floorPlans.length > 1;

    const currentFloorPlan =
      floorPlanProps.floorPlan?.id ??
      reservationSettings?.standardFloorPlan ??
      null;

    return {
      multipleFloorPlans,
      shiftOptions: [
        {
          id: null,
          label: multipleFloorPlans ? 'G. Tag (M. Tischpläne)' : 'All day',
        },
        ...(shifts
          ?.filter((s) => !s.disabled && s.active)
          ?.sort((a, b) => (a?.start || 0) - (b?.start || 0))
          .map((s) => {
            if (multipleFloorPlans) {
              const fP =
                s?.additional?.floorPlan ??
                reservationSettings?.standardFloorPlan ??
                null;

              if (fP !== currentFloorPlan) {
                return { id: s.id, label: `${s.name} (A. Tischplan)` };
              }
            }

            return { id: s.id, label: s.name || '' };
          }) || []),
      ],
    };
  }, [shifts, floorPlanProps]);

  const classNames = classHelper([
    'tableplan-container',
    (currentTable || currentReservation || currentWaitinglistEntry) &&
      'sidebar-open',
    editReservation && 'edit-reservation',
  ]);

  useEffect(() => {
    setdate(date);
  }, [date, setdate]);

  const currentShift = useMemo(() => {
    if (!currentShiftId) return null;

    return shifts?.find((s) => s.id === currentShiftId) ?? null;
  }, [currentShiftId, shifts]);

  const bestOccassion = useMemo(() => {
    if (occassions.length === 1) return occassions[0].id;

    if (currentShift) {
      return currentShift?.occassions[0];
    } else if (date === dateHelper()) {
      let currentTime = timeToBlock(timeHelper());

      let shift = shifts?.find(
        (s) => s.start <= currentTime && s.close >= currentTime && !s.closed
      );

      return shift?.occassions[0];
    } else {
      return undefined;
    }
  }, [currentShift, shifts, date]);

  const handleFinishAllReservations = () => {
    alert({
      title: 'Finish all Reservations',
      description: 'Are you sure that you want to finish all reservations?',
      titleTranslation: 'reservations',
      descriptionTranslation: 'reservations',
      onSubmit: async () => {
        const currentReservations = reservations.filter(
          (r) =>
            r.currentStatus === CurrentStatus.SEATED ||
            r.currentStatus === CurrentStatus.HAS_ORDERED ||
            r.currentStatus === CurrentStatus.PAID
        );

        const promises = currentReservations.map((r) =>
          db
            .collection('requestsV03')
            .doc(r.id)
            .update({
              done: true,
              updatedAt: Date.now(),
              updatedBy: uid,
              updateNote: {
                note: 'Wurde als fertig markiert',
                updatedAt: Date.now(),
              },
            })
        );

        await Promise.all(promises);
      },
    });
  };

  const handleCurrentReservation = (id: string | null) => {
    setcurrentReservation(id);
    setcurrentTable(null);
  };

  useEffect(() => {
    if (currentReservation) {
      let resa = filteredReservations.find((r) => r.id === currentReservation);

      if (resa?.space && resa?.space !== floorPlanProps?.space) {
        floorPlanProps?.onSpaceChange?.(resa.space);
      }
    }
  }, [currentReservation]);

  useEffect(() => {
    if (editReservation) {
      if (
        editReservation?.space &&
        editReservation?.space !== floorPlanProps?.space
      ) {
        floorPlanProps?.onSpaceChange?.(editReservation.space);
      }
    }
  }, [editReservation]);

  const closable = useMemo(
    () =>
      endOfShift &&
      !!reservations.filter(
        (r) =>
          r.currentStatus === CurrentStatus.SEATED ||
          r.currentStatus === CurrentStatus.HAS_ORDERED ||
          r.currentStatus === CurrentStatus.PAID
      ).length,
    [endOfShift, reservations]
  );

  useEffect(() => {
    if (
      currentReservation ||
      currentTable ||
      editReservation ||
      currentWaitinglistEntry
    ) {
      document.getElementsByTagName('body')[0].classList.add('sidebarOpen');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('sidebarOpen');
    }
    return () => {
      document.getElementsByTagName('body')[0].classList.remove('sidebarOpen');
    };
  }, [
    currentReservation,
    currentTable,
    editReservation,
    currentWaitinglistEntry,
  ]);

  const inPast = date < dateHelper();

  const pathsWithWaitinglist = useMemo(() => {
    return paths
      .filter((p) => !Shuffle.state || !p.disableOnShuffle)
      .map((p) => {
        if (p.id === 'largeGroups') {
          return {
            ...p,
            notifications: hasPendingLargeGroupRequests,
          };
        }

        return p;
      });
  }, [paths, hasPendingLargeGroupRequests, Shuffle]);

  return (
    <Box
      background
      className={classHelper([
        'tablePlan-screen',
        hideLiveResas && 'hideLiveResas',
      ])}
    >
      {hideLiveResas && (
        <div className="hideLiveResasMsg">
          <Typography color="inherit" variant="text-5">
            Achtung! Live-Reservierungen nicht im Blick, da der gesetzte Filter
            die aktuelle Uhrzeit nicht anzeigt. Um alles im Blick zu behalten
            setze den Filter in der Kopfleiste auf die aktuelle Schicht, oder
            "Ganzer Tag"
          </Typography>
          <IconButton size="x-small" onClick={toggleHideLiveResas}>
            <Close
              color="inherit"
              style={{ height: 14, width: 14, color: '#fff' }}
            />
          </IconButton>
        </div>
      )}
      <NavBar
        title={Shuffle.state ? 'Shuffle Reservation Mode' : 'Reservations'}
        reservationBook
        right={
          !Shuffle.state ? (
            <>
              <QuickModal />
              <EventModal restaurantId={restaurantId} />
              <Button
                variant="primary"
                translation="reservations"
                onClick={() => handleNewReservation(date)}
                endIcon={(p) => <Add {...p} />}
              >
                New Reservation
              </Button>
            </>
          ) : (
            <></>
          )
        }
        hideChildren={!!editReservation}
        childrenContainerStyle={{
          width: '190px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          transform: 'translateX(50px)',
        }}
      >
        {!Shuffle.state ? (
          <>
            <DateDropdown value={date} onChange={onDateChange} padding={0} />
            {shiftOptions.length > 2 ? (
              <Dropdown
                translation="common"
                value={currentShiftId}
                onChange={setCurrentShift}
                buttonColor={multipleFloorPlans ? 'secondary' : 'subdued'}
                options={shiftOptions}
                optionElement={OptionElement}
                style={{
                  width: '100%',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: multipleFloorPlans
                    ? 'var(--color-secondary)'
                    : undefined,
                }}
              />
            ) : (
              <Typography
                variant="text-3"
                color="subdued"
                translation="common"
                style={{
                  whiteSpace: 'nowrap',
                  display: 'block',
                  width: '100%',
                  textAlign: 'center',
                  padding: '0 1rem',
                }}
              >
                All Day
              </Typography>
            )}
          </>
        ) : (
          <></>
        )}
      </NavBar>
      <MailboxAlert />
      <FeatureNavigation
        date={date}
        paths={pathsWithWaitinglist}
        current="tablePlan"
        warnOnLeave={!!editReservation}
        onLeave={() => seteditReservation(null)}
        className="featureNavigation"
      >
        <>
          <IconButton
            onClick={() => setshowWarnings((x) => !x)}
            style={{ marginBottom: 12 }}
          >
            <Badge color="primary" number={warnings.length}>
              <Warning
                htmlColor={
                  showWarnings
                    ? 'var(--color-warning)'
                    : 'var(--color-disabled)'
                }
              />
            </Badge>
          </IconButton>
          <IconButton
            disabled={!closable}
            onClick={handleFinishAllReservations}
            style={{ marginBottom: 12 }}
          >
            <AssignmentTurnedInRounded
              color={closable ? 'secondary' : 'disabled'}
            />
          </IconButton>

          <ShuffleButton date={date} />
        </>
      </FeatureNavigation>
      <Box className={classNames}>
        {!editReservation && (
          <LeftSideBar
            restaurantId={restaurantId}
            date={date}
            reservations={filteredReservations}
            occassions={occassions}
            spaces={floorPlanProps.floorPlan?.spaces || []}
            handleAction={handleAction}
            warnings={warnings.map((w) => w.id)}
            active={currentReservation}
            setActive={handleCurrentReservation}
            loading={reservationLoading}
            currentShift={currentShift}
          />
        )}
        <FloorPlan
          {...floorPlanProps}
          currentTime={currentTime}
          editReservation={editReservation}
          reservations={reservations}
          onTableClick={handleTableClick}
          currentTable={currentTable}
          currentReservationTables={
            reservationProps.reservation?.tables || null
          }
          updateSize={classNames}
          warnings={warnings}
          onCloseSideBar={() => {
            if (editReservation) return;
            setcurrentReservation(null);
            setcurrentTable(null);
          }}
        />
        {currentReservation && !editReservation && (
          <ReservationSideBar
            {...reservationProps}
            occassions={occassions}
            onReservationAction={handleAction}
            onClose={() => setcurrentReservation(null)}
          />
        )}
        {currentTable && !currentReservation && !editReservation && (
          <TableSideBar
            {...tableProps}
            onCloseSideBar={() => setcurrentTable(null)}
            onWalkin={(tables: string[], space?: string) =>
              handleNewReservation(date, true, tables, bestOccassion, space)
            }
            currentTime={currentTime}
            onReservationAction={handleAction}
            setcurrentReservation={handleCurrentReservation}
          />
        )}
        {currentWaitinglistEntry && !editReservation && !currentReservation && (
          <WaitinglistSidebar
            occassions={occassions}
            id={currentWaitinglistEntry}
            onClose={() => setcurrentWaitinglistEntry(null)}
          />
        )}
        {editReservation && !editReservation.new && (
          <EditReservationSidebar
            editReservation={editReservation}
            seteditReservation={seteditReservation}
            settings={settings}
            restaurantId={restaurantId}
            uid={uid || ''}
            occassions={occassions}
            handleSubmit={handleSubmit}
            onClose={() => seteditReservation(null)}
            useSignature={reservationSettings?.signatureRequired}
          />
        )}
        {editReservation && !!editReservation.new && (
          <NewReservationSidebar
            newReservation={editReservation}
            setnewReservation={seteditReservation}
            settings={settings}
            restaurantId={restaurantId}
            uid={uid || ''}
            occassions={occassions}
            handleNext={handleSubmit}
            onClose={() => seteditReservation(null)}
            useSignature={reservationSettings?.signatureRequired}
          />
        )}
      </Box>
      {!!switchReservationState && (
        <SwitchReservationModal {...switchReservationState} />
      )}
    </Box>
  );
};

export default TablePlan;
