import React, { createContext, useState, useEffect, useMemo } from 'react';
import firebase, { db } from 'utils/firebase';
import { useHistory } from 'react-router-dom';
import LoadingScreen from 'Components/Atoms/LoadingScreen';
import { Restaurant, UserData, UserRoles } from 'utils/types';
import useDocument from 'CustomHooks/useDocument';
import { RestaurantUserDocument } from 'gastronaut-shared/types/documents/restaurants';
import useServerTime from 'CustomHooks/useServerTime';
import { IntercomWrapper } from 'App/Dashboard/Screens/Dashboard';
// import { subscribeUser } from '../serviceWorkerRegistration';

export type AuthContextType = {
  user: UserType;
  signInUser: (
    method: string,
    providerData?: ProviderDataProps
  ) => Promise<void>;
  signOutUser: () => Promise<void>;
  signUpUser: (method: string, formData?: FormDataProps) => Promise<void>;
  uid: null | string;
  restaurantId: string | null;
  isAdmin?: boolean;
  setrestaurantId: Function;
  loading?: boolean;
  handleForgotPassword: (email: string) => Promise<void>;
  isGastronautAdmin: boolean;
  DateNow: () => number;
  handleChangePassword: (
    oldPwd: string,
    newPwd: string
  ) => Promise<{
    success: boolean;
    message: string;
  }>;
  roleMap: {
    admin: boolean;
    chefWaiter: boolean;
    waiter: boolean;
    tablet: boolean;
  };
};
interface AuthContextProps {
  children: any;
  restaurantId: null | string;
  setrestaurantId: Function;
  currentVersion?: string;
  storybook?: boolean;
}

// interface Restaurant {
//   id: string;
//   role: string;
// }

interface UserType {
  uid: string | null | undefined;
  data: UserData | null | undefined;
  signInError: false | string;
}

export type ProviderDataProps = {
  email: string;
  password: string;
  rememberMe?: boolean;
};

export type FormDataProps = {
  email: string;
  password: string;
};

export const AuthContext = createContext<AuthContextType>({
  user: { data: undefined, signInError: false, uid: undefined },
  signInUser: async () => {},
  signOutUser: async () => {},
  signUpUser: async () => {},
  uid: null,
  restaurantId: null,
  isAdmin: false,
  setrestaurantId: () => {},
  handleForgotPassword: async () => {},
  handleChangePassword: (...args: any) =>
    new Promise<{ success: true; message: '' }>((resolve: Function) => {
      setTimeout(() => {
        console.log(args);
        resolve();
      }, 1000);
    }),
  isGastronautAdmin: true,
  roleMap: {
    admin: false,
    chefWaiter: false,
    waiter: true,
    tablet: false,
  },
  DateNow: () => Date.now(),
});

const extractRestaurantId = () => {
  let path = window.location.pathname.split('/');
  return path[1];
};

const AuthContextProvider = ({
  children,
  restaurantId,
  setrestaurantId,
  currentVersion,
  storybook = false,
}: AuthContextProps) => {
  const history = useHistory();
  const urlRestaurantId = extractRestaurantId();

  // console.log({ urlRestaurantId })
  console.log({ currentVersion });

  const [user, setuser] = useState<UserType>({
    uid: undefined,
    data: undefined,
    signInError: false,
  });
  const [loading, setloading] = useState(true);

  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const timeDifference = useServerTime();

  const DateNow = () => Date.now() + timeDifference;

  const signUpUser = async (
    method: string,
    formData?: {
      email: string;
      password: string;
    }
  ): Promise<void> =>
    new Promise((resolve: Function, reject) => {
      let provider: any = null;

      switch (method) {
        case 'email': {
          if (!formData) return resolve();

          const { email, password } = formData;

          return firebase
            .auth()
            .createUserWithEmailAndPassword(email, password)
            .then((res) => resolve())
            .catch(function (error) {
              console.log('error in sign in with email', error);
              resolve();
            });
        }
        case 'google': {
          provider = new firebase.auth.GoogleAuthProvider();
          provider.addScope('email');
          break;
        }
        case 'facebook': {
          provider = new firebase.auth.FacebookAuthProvider();
          provider.addScope('email');
          break;
        }

        default:
          break;
      }

      firebase
        .auth()
        .signInWithPopup(provider)
        .then((res) => resolve())
        .catch((error) => {
          console.log(error);
          resolve();
        });
    });

  const signInUser = async (
    method: string,
    providerData?: ProviderDataProps
  ): Promise<void> =>
    new Promise((resolve: Function, reject) => {
      let provider: any = null;

      switch (method) {
        case 'email': {
          if (!providerData) return resolve();

          const { email = '', password = '' } = providerData;

          return firebase
            .auth()
            .signInWithEmailAndPassword(email, password)
            .then(() => resolve())
            .catch(function (error) {
              let signInError = error.message;
              console.log('error on signIn', error);
              setuser((u) => ({ ...u, signInError }));
              resolve();
            });
        }
        case 'google': {
          provider = new firebase.auth.GoogleAuthProvider();
          provider.addScope('email');
          break;
        }
        case 'facebook': {
          provider = new firebase.auth.FacebookAuthProvider();
          provider.addScope('email');
          break;
        }

        default:
          break;
      }

      firebase
        .auth()
        .signInWithPopup(provider)
        .then(() => resolve())
        .catch((error) => {
          var signInError = error.message;
          resolve();
          setuser((u) => ({ ...u, signInError }));
        });
    });

  const signOutUser = () =>
    firebase
      .auth()
      .signOut()
      .then(() => {
        setuser((u) => ({ ...u, data: null }));
        setrestaurantId(null);
      });

  const handleUserData = async (currentUser: firebase.User) => {
    let displayName = currentUser.displayName;
    let email = currentUser.email;
    let emailVerified = currentUser.emailVerified;
    let photoURL = currentUser.photoURL;
    let isAnonymous = currentUser.isAnonymous;
    let uid = currentUser.uid;
    let provider = currentUser.providerData[0]?.providerId;

    // window.uid = uid;

    // console.log({ currentUser });

    const userRef = await db.collection('users').doc(uid).get();

    if (!userRef.exists) {
      //Check for onboarding session
      const onBoardingRef = await db
        .collection('onboardingSessions')
        .where('uid', '==', uid)
        .get();

      const sessions: any = [];

      onBoardingRef.forEach((doc) =>
        sessions.push({ ...doc.data(), id: doc.id })
      );
      if (sessions.length) {
        if (
          window.location.href.includes('onboarding') &&
          !window.location.href.includes(
            `onboarding/${sessions[0].tourId}/${sessions[0].id}`
          )
        ) {
          let conf = window.confirm(
            'Willst du die bestehende Sitzung fortfahren?'
          );

          let data: UserData = {
            displayName,
            email,
            emailVerified,
            photoURL,
            isAnonymous,
            uid,
            role: UserRoles.WAITER,
            restaurants: [],
            // appVersion: currentVersion,
          };
          setuser((u) => ({ ...u, uid, data, signInError: false }));

          if (conf) {
            history.push(`/onboarding/${sessions[0].tourId}/${sessions[0].id}`);
            return;
          }

          setloading(false);
          return;
        } else {
          history.push(`/onboarding/${sessions[0].tourId}/${sessions[0].id}`);
          let data: UserData = {
            displayName,
            email,
            emailVerified,
            photoURL,
            isAnonymous,
            uid,
            role: UserRoles.WAITER,
            restaurants: [],
            // appVersion: currentVersion,
          };
          setuser((u) => ({ u, uid, data, signInError: false }));
          setloading(false);
          return;
        }
      }

      setuser({ data: null, uid: null, signInError: false });
      signOutUser();
      return;
    }

    let userData = userRef.data();

    let restaurants: any[] = userData?.restaurants || [];
    let appVersion = userData?.appVersion;

    if (!appVersion) appVersion = currentVersion;

    if (userData?.isGastronautAdmin) {
      let allRestaurants: Restaurant[] = [];
      const restaurantsRef = await db
        .collection('restaurantData')
        .where('status', '!=', 'deleted')
        .get();
      restaurantsRef.forEach((doc) => {
        if (restaurants.find((res) => res.id === doc.id)) return;

        if (doc.data().status === 'deleted') return;

        allRestaurants.push({
          id: doc.id,
          name: displayName || '',
          admin: true,
          role: 'admin',
          title: doc.data()?.name || doc.id,
          products: doc.data()?.productsOwned || [],
        });
      });
      restaurants = [...restaurants, ...allRestaurants];
    }

    let data: UserData = {
      displayName,
      email,
      emailVerified,
      photoURL,
      isAnonymous,
      uid,
      provider,
      role: UserRoles.WAITER,
      ...userData,
      restaurants,
      // appVersion,
    };

    // console.log({ restaurants });

    if (
      urlRestaurantId &&
      (userData?.isGastronautAdmin ||
        restaurants.find((r) => r.id === restaurantId))
    ) {
      setrestaurantId(urlRestaurantId);
    } else if (restaurants.length === 0 && !restaurantId) {
      setrestaurantId(null);
    } else {
      let restaurant =
        restaurantId && restaurants.find((r) => r.id === restaurantId)
          ? restaurantId
          : restaurants[0].id;

      setrestaurantId(restaurant);
    }

    console.log(restaurantId);

    // window.userData = data;
    setuser((u) => ({ ...u, data, uid, signInError: false }));
    setloading(false);

    if (window.location.pathname.startsWith('/auth')) {
      history.push('/');
    }
  };

  (window as any).restaurantId = restaurantId;

  useEffect(() => {
    if (
      !loading &&
      !user.data &&
      !window.location.pathname.startsWith('/auth') &&
      !window.location.pathname.startsWith('/onboarding') &&
      !storybook
    ) {
      history.push('/auth/login');
    }
  }, [user.data, loading, history, storybook]);

  useEffect(() => {
    if (!('data' in user)) {
      let currentUser = firebase.auth().currentUser;

      if (currentUser) {
        handleUserData(currentUser);
      } else {
        setloading(false);
      }
    }
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    if (
      (!loading &&
        !restaurantId &&
        user?.uid &&
        !window.location.href.includes('onboarding') &&
        !window.location.href.includes('auth')) ||
      (restaurantId === 'onboarding' && user?.uid)
    ) {
      db.collection('users')
        .doc(user.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            let data = doc.data();
            if (data?.restaurants?.length) {
              setrestaurantId(data?.restaurants[0].id);
            } else {
              setrestaurantId(data?.restaurant);
            }
          } else if (window.location.href === '/') {
            signOutUser();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, [restaurantId, loading]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (currentUser) {
      if (currentUser) {
        handleUserData(currentUser);
        //@TODO logic for subcribePushNotif : if login > get token, if not request for sub

        // subscribeUser(currentUser.uid);
      } else {
        setloading(false);
      }
    });
    // eslint-disable-next-line
  }, []);

  //Creating an admin for Storybook
  useEffect(() => {
    if (storybook) {
      setuser({
        signInError: false,
        uid: '0IsX30ltw5QOleRG8XHD1tyvCqq1',
        data: {
          displayName: 'Test User',
          email: 'test@gastronaut.ai',
          emailVerified: true,
          photoURL: null,
          isAnonymous: false,
          uid: '0IsX30ltw5QOleRG8XHD1tyvCqq1',
          role: UserRoles.ADMIN,
          restaurants: [
            {
              id: 'restaurant-apollo',
              name: 'Test User',
              admin: true,
              role: 'admin',
              title: 'Restaurant Apollo',
              products: [
                'general',
                'reservation',
                'delivery',
                'vouchers',
                'merchandise',
                'followUp',
                'menu',
                'feedback',
              ],
            },
          ],
        },
      });
    }
  }, [storybook]);

  useEffect(() => {
    if (!loading) {
      if (restaurantId && user.data) {
        const currentRestaurant = user.data.restaurants.find(
          (r) => r.id === restaurantId
        );

        // console.log({ restaurantId, currentRestaurant });

        if (
          !currentRestaurant &&
          !storybook &&
          !window.location.pathname.startsWith('/onboarding')
        ) {
          //     if (!loading) {
          //       if (restaurantId && user.data) {
          //         const currentRestaurant = user.data.restaurants.find(
          //           r => r.id === restaurantId
          //         );

          //         console.log({ restaurantId, currentRestaurant });

          //         if (!currentRestaurant && !storybook) {
          //           history.push('/');
          //         } else if (currentRestaurant && currentRestaurant.admin) {
          //           setIsAdmin(true);
          //         }
          //       } else if (
          //         !storybook &&
          //         !restaurantId &&
          //         !window.location.pathname.startsWith('/auth')
          //       ) {

          history.push('/');
        }
      }
    }
  }, [restaurantId, loading, history, user.data, storybook]);

  const uid = storybook
    ? '0IsX30ltw5QOleRG8XHD1tyvCqq1'
    : user?.data?.uid || null;

  const handleForgotPassword = async (email: string) =>
    firebase.auth().sendPasswordResetEmail(email);

  const handleChangePassword = async (oldPwd: string, newPwd: string) => {
    let currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return { success: false, message: 'No current user' };
    }
    const credential = firebase.auth.EmailAuthProvider.credential(
      currentUser.email ?? user.data?.email ?? '',
      oldPwd
    );
    //Check that the old pwd is the good one
    const answer = await currentUser
      .reauthenticateWithCredential(credential)
      .then(async () => {
        try {
          //Update the new pwd
          await currentUser?.updatePassword(newPwd);
          return { success: true, message: 'Password updated' };
        } catch (error: any) {
          console.log('error on reset password', error);
          return { success: false, message: error.message as string };
        }
      })
      .catch((error: any) => {
        console.log('error on handle change password', error);
        return { success: false, message: 'The actual password is wrong' };
      });

    return answer;
  };

  const isGastronautAdmin = !!user?.data?.isGastronautAdmin;

  const [restaurantUser] = useDocument<RestaurantUserDocument>(
    `/restaurants/${restaurantId}/users`,
    uid
  );

  const roleMap = useMemo(() => {
    if (isGastronautAdmin) {
      return {
        admin: true,
        waiter: true,
        chefWaiter: true,
        tablet: true,
      };
    }

    if (!restaurantUser?.data) {
      return {
        admin: false,
        waiter: true,
        chefWaiter: false,
        tablet: false,
      };
    }

    return {
      admin: !!restaurantUser.data?.admin,
      waiter: true,
      chefWaiter: !!restaurantUser.data?.chefWaiter,
      tablet: !!restaurantUser.data?.tablet,
    };
  }, [restaurantUser, isGastronautAdmin]);

  return (
    <AuthContext.Provider
      value={{
        user,
        signInUser,
        signOutUser,
        signUpUser,
        uid,
        restaurantId,
        setrestaurantId,
        isAdmin: roleMap.admin,
        handleForgotPassword,
        loading,
        handleChangePassword,
        isGastronautAdmin,
        roleMap,
        DateNow,
      }}
    >
      <LoadingScreen loading={loading} />
      {children}
      {/* {!!user.data && (
        <IntercomWrapper data={user?.data || {}} uid={user?.uid || ''} />
      )} */}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
