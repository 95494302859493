import React from 'react';
import Box from 'Components/Atoms/Box';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useHistory } from 'react-router-dom';
import Typography from 'Components/Atoms/Typography';
import { classHelper } from 'utils/helper';
import './styles.scss';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import usePinAuth, { SecurityPlusKeys } from 'CustomHooks/usePinAuth';
import LockedProductIcon from 'Components/Atoms/Icons/LockedProduct';

export type ProductIconProps = {
  id: string;
  label: string;
  notifications?: null | number;
  linkTo: Function;
  restaurantId: string | null;
  hide?: boolean;
  hideLabel?: string;
  isNew?: boolean;
  className?: string;
  locked?: boolean;
  authorize?: SecurityPlusKeys;
  promotionalLink?: Function;
};

const ProductIcon: React.FC<ProductIconProps> = ({
  id,
  linkTo,
  label,
  restaurantId,
  hide = false,
  hideLabel,
  isNew = false,
  className,
  locked = false,
  authorize,
  promotionalLink,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery('(max-width: 450px)');

  const { authenticate } = usePinAuth();

  if (id === 'filler-box') {
    return <Box width="80px"></Box>;
  }

  return (
    <Box
      flex
      className={classHelper([
        'inline-column',
        'product-icon',
        hide && 'hidden',
        isNew && 'isNew',
        className,
        locked && !hide && 'isLocked',
      ])}
    >
      <ButtonBase
        style={{ marginBottom: 8, color: '#fff' }}
        href={
          !!hide && !!hideLabel
            ? 'https://gastronomen.gastronaut.ai/module#coming'
            : id === 'faq'
            ? 'https://get.gastronaut.ai/help-center/help-normal'
            : ''
        }
        target="_blank"
        onClick={
          !hide
            ? () =>
                authenticate(authorize ?? 'none', () =>
                  history?.push(linkTo(restaurantId))
                )
            : promotionalLink
            ? () => history?.push(promotionalLink(restaurantId))
            : undefined
        }
      >
        {!locked && isNew && <span className="new-badge">New</span>}
        {locked && !hide && (
          <LockedProductIcon
            strokeColor="var(--color-bg)"
            className="locked-icon"
            width="32"
            height="32"
          />
        )}
        <Box
          flex
          className={classHelper(['center', isMobile && 'mobile-version'])}
          padding={20}
          elevation={0}
        >
          <img
            height={40}
            width={40}
            alt={id}
            src={
              !isMobile
                ? `/products/${id}.svg`
                : hide
                ? `/products/mobile/${id}.svg`
                : `/products/${id}.svg`
            }
          />
          {!!hide && !!hideLabel && (
            <Typography
              className="hideLabel"
              variant="text-3"
              textAlign="center"
              translation={null}
            >
              {hideLabel}
            </Typography>
          )}
        </Box>
      </ButtonBase>
      {!isMobile && (
        <Typography
          variant="text-3"
          textAlign="center"
          translation="products"
          style={{ whiteSpace: 'nowrap' }}
        >
          {label}
        </Typography>
      )}
    </Box>
  );
};

export default ProductIcon;
