import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useCollection from 'CustomHooks/useCollection';
import {
  CreateMollieParameters,
  CreateParameters,
  NewTemplateParams,
  Payment,
  Redeem,
  Shipping,
  VoucherActionParameters,
  VoucherActions,
  VoucherData,
  VoucherTemplate,
  VoucherTemplateType,
} from 'types/vouchersV02';
import server, { mainServer } from 'utils/server';
import { Amount } from '@mollie/api-client/dist/types/src/data/global';
import { useRouter } from 'CustomHooks/useRouter';
import useAlert from 'CustomHooks/useAlert';
import useDocument from 'CustomHooks/useDocument';
import { db } from 'utils/firebase';
import { AxiosError } from 'axios';
import { createRandomId, dateHelper } from 'utils/helper';
import { TemplateDocument } from 'gastronaut-shared/types/documents';
import VoucherActionModal from 'App/Voucher/Components/ActiveVoucherModal/Components/VoucherAction';
import { PaymentStatus } from '@mollie/api-client';
import useAuth from 'CustomHooks/useAuth';
import useTime from 'CustomHooks/useTime';

interface Props {
  restaurantId: null | string;
  //setrestaurantId: Function;
  children: any;
}

type Context = {
  active: boolean;
  vouchers: {
    ref: any;
    data: (VoucherData & {
      id: string;
    })[];
    loading: boolean;
    error: string | null;
  };
  templates: {
    ref: any;
    data: (VoucherTemplate & {
      id: string;
    })[];
    loading: boolean;
    error: string | null;
  };
  submitNewVoucher: (params: CreateParameters) => Promise<void>;
  editTemplate: (templateId: string) => Promise<void>;
  activeVoucherId: string | null;
  setactiveVoucherId: React.Dispatch<React.SetStateAction<string | null>>;
  submitVoucherAction: ({
    action,
    parameters,
  }: VoucherActionParameters) => Promise<void>;
  newTemplate: (x?: NewTemplateParams) => Promise<void>;
  templateDraft: {
    ref: any;
    data:
      | (VoucherTemplate & {
          STEP: number;
          new?: boolean;
        })
      | null;
    loading: boolean;
    error: string | null;
    exists: boolean;
  };
  setTemplateDraft: React.Dispatch<
    React.SetStateAction<
      | (VoucherTemplate & {
          STEP: number;
          new?: boolean;
        })
      | null
    >
  >;

  tabs: {
    id: string;
    label: string;
  }[];
  tab: string;
  settab: React.Dispatch<React.SetStateAction<string>>;
  search: string | null;
  setsearch: React.Dispatch<React.SetStateAction<string | null>>;
  filteredVouchers: (VoucherData & { id: string })[];
  restaurantId: string;
  voucherAction: (
    code: string,
    action: VoucherActionParameters['action'],
    id?: string
  ) => Promise<void>;
  action: VoucherActionParameters | null;
  setAction: React.Dispatch<
    React.SetStateAction<VoucherActionParameters | null>
  >;
  uid: string;
  statistics?: {
    expired: number[];
    total: number[];
    refunded: number[];
    redeemed: number[];
    remaining: number[];
  };

  filters: string;
  setfilters: React.Dispatch<React.SetStateAction<string>>;

  sessions: (CreateMollieParameters & {
    id: string;
    createdAt: number;
    voucherId: string;
  })[];

  redeems: Redeem[];
  payments: Payment[];

  currentTemplateId: string | null;
  setCurrentTemplateId: React.Dispatch<React.SetStateAction<string | null>>;

  shippingMethods: {
    ref: any;
    data: (Shipping & {
      id: string;
    })[];
    loading: boolean;
    error: string | null;
  };

  submitTemplate: () => Promise<void>;
  closeTemplate: () => Promise<void>;
  previewUrl: string;
};

const tabs = [
  { id: 'all', label: 'All' },
  { id: 'valid', label: 'Valid' },
  { id: 'invalid', label: 'Invalid' },
];

const EmptyState = {
  active: false,
  vouchers: {
    ref: null,
    data: [],
    loading: true,
    error: null,
  },
  shippingMethods: {
    ref: null,
    data: [],
    loading: true,
    error: null,
  },
  templates: {
    ref: null,
    data: [],
    loading: true,
    error: null,
  },
  submitNewVoucher: async () => {},
  editTemplate: async () => {},
  voucherAction: async () => {},
  newTemplate: async () => {},
  activeVoucherId: null,
  setactiveVoucherId: () => {},
  templateDraft: {
    ref: null,
    data: null,
    loading: true,
    error: null,
    exists: false,
  },
  setTemplateDraft: () => {},
  tabs,
  tab: 'all',
  settab: () => {},
  settabs: () => {},
  search: null,
  setsearch: () => {},
  filteredVouchers: [],
  restaurantId: '',
  submitVoucherAction: async () => {},
  action: null,
  setAction: () => {},
  uid: '',
  filters: 'all',
  setfilters: () => {},
  sessions: [],
  redeems: [],
  payments: [],

  setCurrentTemplateId: () => {},
  currentTemplateId: null,
  submitTemplate: async () => {},
  closeTemplate: async () => {},
  previewUrl: '',
};

export const VoucherContext = createContext<Context>(EmptyState);

/*
    [x] - Voucher List
    [x] - Create Voucher
    [x] - Redeem Voucher
    [x] - Refund Payment
    [x] - Cancel Redeems
    [ ] - Voucher Template List
    [ ] - Create Template
    [ ] - Edit Template
    [ ] - Statistics
        - [ ] Outtanding Amount
        - [ ] Redeemed Amount 

    [x] - Search Functionality
        [ ] - v02
    [x] - History
    [ ] - Emails
    [ ] - SMS Link
    [ ] - Show pending payments?
*/

const VoucherContextProvider = (props: Props) => {
  const [activeVoucherId, setactiveVoucherId] = useState<string | null>(null);

  const { uid } = useAuth();

  const [active, setActive] = useState(false);

  const [filters, setfilters] = useState<string>('');

  const [vouchers] = useCollection<VoucherData>(active ? `/vouchersV02` : '', {
    filter: [['restaurantId', '==', props.restaurantId]],
    sort: ['createdAt', 'desc'],
  });

  const [shippingMethods] = useCollection<Shipping>(
    active ? `restaurants/${props.restaurantId}/shippingMethods` : ''
  );

  const dateNow = useTime();

  const [sessions] = useCollection<
    CreateMollieParameters & { createdAt: number; voucherId: string }
  >(active ? `/voucherSessions` : '', {
    filter: [
      ['restaurantId', '==', props.restaurantId],
      ['createdAt', '>=', dateNow - 60 * 60 * 1000],
    ],
    sort: ['createdAt', 'desc'],
  });

  console.log({
    vouchers,
    active,
    activeVoucherId,
  });

  const [templates] = useCollection<VoucherTemplate>(
    active ? `/restaurants/${props.restaurantId}/voucherTemplates` : ''
  );

  const router = useRouter();

  const alert = useAlert();

  useEffect(() => {
    if (router.query?.code) {
      setactiveVoucherId(
        router.query.code.includes('_') || router.query.code == 'new'
          ? router.query.code
          : `${props.restaurantId}_${router.query.code}`
      );
    }

    setActive(router.pathname.includes('/voucher'));
  }, [router.pathname, router.query]);

  const submitNewVoucher = async (params: CreateParameters) => {
    try {
      const response = await server.post<{
        voucher: VoucherData | null;
        amount: Amount;
        paymentLink: string | null;
      }>(`/v03/vouchersV02/${props.restaurantId}/create`, params);

      if (params.type === 'mollie') {
        alert({
          title: 'Zahlungslink wurde versendet',
          description: `Der Zahlungslink wurde an ${params.phoneNumber
            .replace('+49', '0')
            .replace(
              /\W/gm,
              ''
            )} versendet. Der Gast kann nun den Gutschein innerhalb der nächsten Stunde bezahlen.`,
        });
        setactiveVoucherId(null);
      } else {
        router.replace(
          `/${params.restaurantId}/vouchers?code=${response.data.voucher?.code}`
        );
      }
    } catch (error: any) {
      alert({
        title: 'Es ist ein Fehler aufgetreten',
        description: error?.response?.data?.message ?? error.message,
      });
    }
  };

  const [action, setAction] = useState<VoucherActionParameters | null>(null);

  const voucherAction = async (
    code: string,
    action: VoucherActionParameters['action'],
    id?: string
  ) => {
    code = code.includes('_') ? code.split('_')[1] : code;

    let voucher: VoucherData | undefined = vouchers.data.find(
      (v) => v.code === code
    );

    if (!voucher) {
      voucher = await db
        .collection(`/vouchersV02`)
        .doc(`${props.restaurantId}_${code}`)
        .get()
        .then((doc) => doc.data() as VoucherData);
    }

    if (!voucher) return;

    switch (action) {
      case VoucherActions.redeem: {
        setAction({
          action: VoucherActions.redeem,
          parameters: {
            code: voucher.code,
            amount: {
              value: voucher.remainingAmount?.value ?? '0.00',
              currency: voucher.remainingAmount?.currency ?? 'EUR',
            },
            description: '',
            createdBy: '',
            vatRate: '19',
            // items: [],
            createdAt:
              new Date().toISOString().slice(0, 11) +
              new Date().toLocaleTimeString('de-DE').slice(0, 5),
            maxAmount: voucher.remainingAmount,
          },
        });

        break;
      }
      case VoucherActions.cancelRedeem: {
        if (!id) return;

        const redeem = voucher.redeems?.find((r) => r.id === id);

        if (!redeem) return;

        setAction({
          action: VoucherActions.cancelRedeem,
          parameters: {
            code: voucher.code,
            redeemId: id ?? '',
            reason: '',
            createdBy: '',
          },
        });

        break;
      }
      case VoucherActions.refundPayment: {
        // if (!id) return;

        let payment = voucher.payments?.find((r) => r.id === id);

        if (!payment && !id && voucher.payments.length === 1) {
          payment = voucher.payments[0];
        }

        if (!payment) return;

        console.log({
          payment,
          id,
          voucher,
        });

        setAction({
          action: VoucherActions.refundPayment,
          parameters: {
            code: voucher.code,
            paymentId: payment.id ?? payment.paymentId ?? id,
            amount: payment.amount,
            description: '',
            createdBy: '',
            maxAmount: payment.amount,
            testmode: payment.testmode,
          },
        });

        break;
      }
      case VoucherActions.addPayment: {
        setAction({
          action: VoucherActions.addPayment,
          parameters: {
            amount: {
              value: '0.00',
              currency: 'EUR',
            },
            method: 'cash',
            createdAt: new Date().toISOString(),
            description: '',
            restaurantId: props.restaurantId ?? '',
            status: PaymentStatus.paid,
            type: 'cash',
            voucherId: `${props.restaurantId}_${code}`,
            reference: '',
            testmode: !!props.restaurantId?.endsWith('-demo'),
            createdBy: '',
          },
        });

        break;
      }
      case VoucherActions.downloadPdf: {
        let designs =
          templates.data.find((t) => t.id === voucher?.templateId)?.designs ??
          [];

        if (designs.length === 0) {
          return submitVoucherAction({
            action: VoucherActions.downloadPdf,
            parameters: {
              voucherId: `${props.restaurantId}_${code}`,
              design: '',
            },
          });
        }

        setAction({
          action: VoucherActions.downloadPdf,
          parameters: {
            voucherId: `${props.restaurantId}_${code}`,
            design: voucher?.design ?? '',
          },
        });
        break;
      }
      case VoucherActions.sendPerEmail: {
        setAction({
          action: VoucherActions.sendPerEmail,
          parameters: {
            email: voucher.guest?.email ?? '',
          },
        });
        break;
      }
      case VoucherActions.editRedeem: {
        const redeem = voucher.redeems?.find((r) => r.id === r.id);

        if (!redeem) return;

        setAction({
          action: VoucherActions.editRedeem,
          parameters: {
            redeemId: redeem.id,
            code: voucher.code,
            amount: {
              value: redeem.amount.value,
              currency: redeem.amount.currency,
            },
            description: redeem.description,
            updatedBy: '',
            vatRate: redeem.vatRate ?? '',
            items: redeem.items,
          },
        });

        break;
      }
      default:
        break;
    }
  };

  const submitVoucherAction = async ({
    action,
    parameters,
  }: VoucherActionParameters) => {
    switch (action) {
      case VoucherActions.redeem: {
        if (!parameters.createdBy) {
          alert({
            title: 'Ungültiger Name',
            description: 'Bitte geben Sie einen gültigen Namen ein',
          });
          return;
        }

        if (!parameters.description) {
          alert({
            title: 'Ungültige Beschreibung',
            description: 'Bitte geben Sie eine gültige Beschreibung ein',
          });
          return;
        }

        const fullAmount = parameters.items?.reduce((acc, cV) => {
          return acc + parseFloat(cV.totalAmount.value);
        }, 0);

        if (parameters.amount.value === '0.00' && !fullAmount) {
          alert({
            title: 'Ungültiger Betrag',
            description: 'Der Betrag muss größer als 0 sein',
          });
          return;
        }

        // @TODO Redeem Voucher

        break;
      }
      case VoucherActions.cancelRedeem: {
        if (!parameters.createdBy) {
          alert({
            title: 'Ungültiger Name',
            description: 'Bitte geben Sie einen gültigen Namen ein',
          });
          return;
        }

        if (!parameters.reason) {
          alert({
            title: 'Ungültiger Grund',
            description: 'Bitte geben Sie einen gültigen Grund ein',
          });
          return;
        }

        let redeem = parameters.redeemId
          ? vouchers.data.find((v) =>
              v.redeems?.find((p) => p.id === parameters.redeemId)
            )
          : undefined;

        if (!redeem) {
          alert({
            title: 'Ungültige Einlösung',
            description: 'Die Einlösung konnte nicht gefunden werden',
          });
          return;
        }

        // @TODO Cancel Redeem

        break;
      }
      case VoucherActions.refundPayment: {
        if (!parameters.createdBy) {
          alert({
            title: 'Ungültiger Name',
            description: 'Bitte geben Sie einen gültigen Namen ein',
          });
          return;
        }

        if (!parameters.description) {
          alert({
            title: 'Ungültige Beschreibung',
            description: 'Bitte geben Sie eine gültige Beschreibung ein',
          });
          return;
        }

        let voucher = vouchers.data.find((v) => v.id === activeVoucherId);

        let payment = parameters.paymentId
          ? voucher?.payments?.find(
              (p) =>
                p.id === parameters.paymentId ||
                p.paymentId === parameters.paymentId
            )
          : undefined;

        console.log({
          payment,
          parameters,
          voucher,
        });

        if (!payment) {
          alert({
            title: 'Ungültige Zahlung',
            description: 'Die Zahlung konnte nicht gefunden werden',
          });
          return;
        }

        if (
          !parameters.amount.value ||
          parseFloat(parameters.amount.value) <= 0 ||
          !parameters.amount.currency ||
          parseFloat(parameters.amount.value) > parseFloat(payment.amount.value)
        ) {
          alert({
            title: 'Ungültiger Betrag',
            description: 'Bitte geben Sie einen gültigen Betrag ein',
          });
          return;
        }

        // @TODO Refund Payment

        break;
      }
      case VoucherActions.addPayment: {
        if (!parameters.createdBy) {
          alert({
            title: 'Ungültiger Name',
            description: 'Bitte geben Sie einen gültigen Namen ein',
          });
          return;
        }

        if (
          !parameters.amount.value ||
          parseFloat(parameters.amount.value) <= 0 ||
          !parameters.amount.currency
        ) {
          alert({
            title: 'Ungültiger Betrag',
            description: 'Bitte geben Sie einen gültigen Betrag ein',
          });
          return;
        }

        if (!parameters.description) {
          alert({
            title: 'Ungültige Beschreibung',
            description: 'Bitte geben Sie eine gültige Beschreibung ein',
          });
          return;
        }

        // @TODO Add Payment

        break;
      }
      case VoucherActions.downloadPdf: {
        // open new tab on link
        window.open(
          `https://api.gastronaut.ai/v03/vouchersV02/${
            props.restaurantId
          }/${parameters.voucherId.replace(
            `${props.restaurantId}_`,
            ''
          )}/pdf?design=${parameters.design ?? ''}`,
          '_blank'
        );
        setAction(null);
        return;
      }
      case VoucherActions.sendPerEmail: {
        if (!parameters.email || !parameters.email.includes('@')) {
          alert({
            title: 'Ungültige Email',
            description: 'Bitte geben Sie eine gültige Email Adresse ein',
          });
          return;
        }
        break;
      }
      default:
        break;
    }

    await mainServer
      .post(
        `/v03/vouchersV02/${props.restaurantId ?? ''}/${
          activeVoucherId?.replace(`${props.restaurantId}_`, '') ||
          (parameters as any).code
        }/action`,
        {
          action,
          parameters,
        },
        {
          headers: {
            Authorization: `Bearer user_${uid}`,
          },
        }
      )
      .catch((err) => {
        alert({
          title: 'Es ist ein Fehler aufgetreten',
          description:
            (err as AxiosError).response?.data?.message ?? err.message,
        });
      });

    setAction(null);
  };

  /* Templates */
  const [currentTemplateId, setCurrentTemplateId] = useState<string | null>(
    null
  );

  const [templateDraft, setTemplateDraft] = useDocument<
    VoucherTemplate & { STEP: number; new?: boolean }
  >(
    `/restaurants/${props.restaurantId}/voucherTemplateDrafts`,
    currentTemplateId,
    true
  );

  const editTemplate = async (templateId: string) => {
    try {
      const template = templates.data.find((t) => t.id === templateId);

      if (!template) throw new Error();

      setCurrentTemplateId(templateId);
      await db
        .collection(`/restaurants/${props.restaurantId}/voucherTemplateDrafts`)
        .doc(templateId)
        .set({
          ...template,
          STEP: 1,
        });

      return;
    } catch (error: any) {
      alert({
        title: 'Es ist ein Fehler aufgetreten',
        description: error.message,
      });
    }
  };

  const newTemplate = async (
    p: NewTemplateParams = {
      creationType: 'scratch',
      type: VoucherTemplateType.free,
      parameters: {},
    }
  ) => {
    const { creationType, type, parameters } = p;

    const templateId = createRandomId(8);
    setCurrentTemplateId(templateId);

    let template = SCRATCH[type];

    if (creationType === 'example') {
      // @TODO
    } else if (creationType === 'ai') {
      // @TODO
    } else if (creationType === 'scratch') {
      template = {
        ...template,
        STEP: parameters.step ?? 0,
      };
    }

    await db
      .collection(`/restaurants/${props.restaurantId}/voucherTemplateDrafts`)
      .doc(templateId)
      .set({
        STEP: 0,
        new: true,
        ...template,
      });
  };

  const submitTemplate = async () => {
    if (!templateDraft.data) return;

    const { new: isNew, STEP, ...data } = templateDraft.data;

    if (isNew) {
      const templateId = createRandomId(8);
      await db
        .collection(`/restaurants/${props.restaurantId}/voucherTemplates`)
        .add({
          ...data,
          id: templateId,
        });

      await templateDraft.ref.delete();
      setCurrentTemplateId(null);
    } else {
      await templateDraft.ref.set(data);
    }

    await templateDraft.ref.delete();
    setCurrentTemplateId(null);
  };

  const closeTemplate = async () => {
    templateDraft.ref.delete();
    setCurrentTemplateId(null);
  };

  /* Search */

  const [tab, settab] = useState('all');
  const [search, setsearch] = useState<string | null>(null);

  const filteredVouchers = useMemo(() => {
    if (search) {
      let lCSearch = search?.toLocaleLowerCase();
      return vouchers.data.filter((v) =>
        `${v.code} ${v.guest.name} ${v.guest.phone} ${v.giftee?.name}`
          .toLocaleLowerCase()
          .includes(lCSearch)
      );
    }
    if (filters) {
      if (filters === 'remaining') {
        return vouchers.data.filter(
          (v) => parseFloat(v.remainingAmount.value) > 0
        );
      } else if (filters === 'redeemed') {
        return vouchers.data.filter((v) => !!v.redeems?.length);
      } else if (filters === 'refunded') {
        return vouchers.data.filter(
          (v) => parseFloat(v.refundedAmount.value) > 0
        );
      } else if (filters === 'expired') {
        return vouchers.data.filter((v) => v.expiresAt < dateHelper());
      }
    }
    if (tab === 'valid') return vouchers.data.filter((v) => v.isValid);
    if (tab === 'invalid') return vouchers.data.filter((v) => !v.isValid);

    return vouchers.data;
  }, [vouchers.data, tab, search, filters]);

  const statistics = useMemo(() => {
    function arrHelper(arr: number[]) {
      arr = arr.filter(Boolean);

      return [
        arr.reduce((acc, cV) => {
          return acc + cV;
        }, 0),
        arr.length,
      ];
    }

    let expired = vouchers.data
      .filter((v) => v.expiresAt < dateHelper())
      .reduce((acc, cV) => {
        return [...acc, parseFloat(cV.remainingAmount.value)];
      }, [] as number[]);

    let total = vouchers.data
      .filter((v) => v.isPaid)
      .reduce((acc, cV) => {
        return [...acc, parseFloat(cV.paidAmount.value)];
      }, [] as number[]);

    let refunded = vouchers.data.reduce((acc, cV) => {
      return [...acc, parseFloat(cV.refundedAmount.value)];
    }, [] as number[]);

    let redeemed = vouchers.data
      .filter((v) => v.isCanceled)
      .reduce((acc, cV) => {
        return [
          ...acc,
          parseFloat(cV.paidAmount.value) -
            parseFloat(cV.remainingAmount.value),
        ];
      }, [] as number[]);

    let remaining = vouchers.data
      .filter((v) => v.isPaid)
      .reduce((acc, cV) => {
        return [...acc, parseFloat(cV.remainingAmount.value)];
      }, [] as number[]);

    return {
      expired: arrHelper(expired),
      total: arrHelper(total),
      refunded: arrHelper(refunded),
      redeemed: arrHelper(redeemed),
      remaining: arrHelper(remaining),
    };
  }, [vouchers.data]);

  const redeems = useMemo(() => {
    const redeems = vouchers.data
      .map((v) => v.redeems ?? [])
      .flat()
      .map((x) => ({ ...x, amountValue: parseFloat(x.amount.value) }));

    return redeems.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [vouchers.data]);

  const payments = useMemo(() => {
    const payments = vouchers.data
      .map((v) => v.payments ?? [])
      .flat()
      .map((x) => ({ ...x, amountValue: parseFloat(x.amount.value) }));

    return payments.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
  }, [vouchers.data]);

  const previewUrl = useMemo(() => {
    if (templateDraft.data?.STEP === 0)
      return `https://gastronaut.ai/vouchers/${props.restaurantId}/`;

    return currentTemplateId
      ? `https://gastronaut.ai/vouchers/${props.restaurantId}/${
          templateDraft?.data?.STEP ? 'demo/' : ''
        }${currentTemplateId}`
      : `https://gastronaut.ai/vouchers/${props.restaurantId}/`;
  }, [props.restaurantId, currentTemplateId, templateDraft.data?.STEP]);

  return (
    <VoucherContext.Provider
      value={{
        active,
        templates,
        vouchers,
        activeVoucherId,
        setactiveVoucherId,
        submitNewVoucher,
        editTemplate,
        newTemplate,
        templateDraft,
        setTemplateDraft,
        tabs,
        tab,
        settab,
        search,
        setsearch,
        filteredVouchers,
        restaurantId: props.restaurantId ?? '',

        currentTemplateId,
        setCurrentTemplateId,

        voucherAction,
        submitVoucherAction,
        action,
        setAction,

        uid: uid || '',
        statistics,

        filters,
        setfilters,

        sessions: sessions.data,

        redeems,
        payments,

        shippingMethods,

        submitTemplate,
        closeTemplate,

        previewUrl,
      }}
    >
      <VoucherActionModal />
      {props.children}
    </VoucherContext.Provider>
  );
};

export default VoucherContextProvider;

const SCRATCH: Record<
  VoucherTemplateType,
  VoucherTemplate & { STEP?: number; new?: boolean }
> = {
  free: {
    active: true,
    type: VoucherTemplateType.free,
    createdAt: Date.now(),
    createdBy: '',
    description: '',
    title: '',
    image: '',
    minAmount: {
      value: '0.00',
      currency: 'EUR',
    },
    maxAmount: {
      value: '200.00',
      currency: 'EUR',
    },
    shippingOptions: [
      {
        id: 'online',
        active: true,
        title: 'Print@Home',
        description: '',
        amount: {
          value: '0.00',
          currency: 'EUR',
        },
        needsAddress: false,
        vatRate: '0',
      },
    ],
    designs: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fballoons.png?alt=media',
        id: 'balloons',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fchristmas.png?alt=media',
        id: 'christmas',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fgift.png?alt=media',
        id: 'gift',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fwedding.png?alt=media',
        id: 'wedding',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fparty.png?alt=media',
        id: 'party',
      },
    ],
    expiration: {
      type: 'gesetzlich_vorgeschrieben',
    },
  },
  fixed: {
    active: true,
    type: VoucherTemplateType.fixed,
    createdAt: Date.now(),
    createdBy: '',
    description: '',
    title: '',
    image: '',
    amount: {
      value: '0.00',
      currency: 'EUR',
    },
    shippingOptions: [
      {
        id: 'online',
        active: true,
        title: 'Print@Home',
        description: '',
        amount: {
          value: '0.00',
          currency: 'EUR',
        },
        needsAddress: false,
        vatRate: '0',
      },
    ],
    designs: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fballoons.png?alt=media',
        id: 'balloons',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fchristmas.png?alt=media',
        id: 'christmas',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fgift.png?alt=media',
        id: 'gift',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fwedding.png?alt=media',
        id: 'wedding',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fparty.png?alt=media',
        id: 'party',
      },
    ],
    expiration: {
      type: 'gesetzlich_vorgeschrieben',
    },
  },
  experience: {
    active: true,
    type: VoucherTemplateType.experience,
    createdAt: Date.now(),
    createdBy: '',
    description: '',
    title: '',
    image: '',
    amount: {
      value: '0.00',
      currency: 'EUR',
    },
    options: [],
    shippingOptions: [
      {
        id: 'online',
        active: true,
        title: 'Print@Home',
        description: '',
        amount: {
          value: '0.00',
          currency: 'EUR',
        },
        needsAddress: false,
        vatRate: '0',
      },
    ],
    designs: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fballoons.png?alt=media',
        id: 'balloons',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fchristmas.png?alt=media',
        id: 'christmas',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fgift.png?alt=media',
        id: 'gift',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fwedding.png?alt=media',
        id: 'wedding',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/schillingroofbarhd.appspot.com/o/gift-card-designs%2Fparty.png?alt=media',
        id: 'party',
      },
    ],
    expiration: {
      type: 'gesetzlich_vorgeschrieben',
    },
  },
};

export const useVoucher = () => {
  const ctx = useContext(VoucherContext);

  return ctx;
};
