import Box from 'Components/Atoms/Box';
import Button from 'Components/Atoms/Button';
import { CurrentStatus, Reservation } from 'types/reservations';
import React, { useState } from 'react';
import { CheckCircle, Email, Warning } from 'Components/Atoms/Icons';
import TableSwitch from 'Components/Atoms/Icons/TableSwitch';
import { noop } from 'utils/helper';
import { ReservationActionTypes } from 'Contexts/ReservationContext';
import { useHistory } from 'react-router';
import useAlert from 'CustomHooks/useAlert';
import axios from 'axios';
import useRestaurant from 'CustomHooks/useRestaurant';
import Typography from 'Components/Atoms/Typography';
import EditableField from './EditableField';
import { CustomDataValue } from 'gastronaut-shared/types/helper/customElements';
import Select from 'Components/Atoms/Select';

export type ReservationButtonsProps = {
  status: CurrentStatus | undefined;
  onReservationAction: (
    id: string,
    type: ReservationActionTypes,
    payload?: any
  ) => void;
  id: string;
  tab: string | number | null;
  guestId?: string;
  creditCardCustomerId?: string;
  noShowFee?: {
    amount: number;
    description: string;
    ts: number;
    charge: string;
  };
  fixed?: boolean;
  canReceiveEmail?: boolean;
  guests: number;
  customData: Record<string, CustomDataValue>;
};

const ReservationButtons = ({
  status,
  onReservationAction = noop,
  tab,
  id,
  guestId,
  creditCardCustomerId,
  noShowFee,
  fixed,
  guests,
  canReceiveEmail = false,
  customData,
}: ReservationButtonsProps) => {
  const history = useHistory();

  const alert = useAlert();

  const { restaurantId, experimentalFlags } = useRestaurant();

  const [loading, setloading] = useState(false);

  if (!status) {
    return <div>NO STATUS</div>;
  }
  return (
    <Box
      padding="md"
      style={{ zIndex: 102, display: 'flex', flexDirection: 'column', gap: 16 }}
    >
      {tab === 1 && !!guestId && (
        <Button
          fullWidth
          style={{ border: '1px solid var(--color-border)' }}
          onClick={() =>
            history.push(
              `guestbook?guestId=${guestId.replace(`-${restaurantId}`, '')}`
            )
          }
          translation="reservations"
        >
          Open in Guestbook
        </Button>
      )}
      {tab === 1 &&
        canReceiveEmail &&
        ['normal', 'upcoming', 'overdue'].includes(status) && (
          <Button
            fullWidth
            style={{
              border: '1px solid var(--color-border)',
            }}
            color="subdued"
            typographyProps={{ color: 'textPrimary' }}
            loading={loading}
            onClick={() => {
              if (loading) return;
              setloading(true);
              axios
                .post<{ message: string; send: boolean }>(
                  `https://europe-west3-schillingroofbarhd.cloudfunctions.net/sendReservationConfirmationEmail`,
                  { reservationId: id }
                )
                .then(({ data }) => {
                  return alert({
                    title: data.send
                      ? 'Nachricht versendet'
                      : 'Nachricht konnte nicht gesendet werden',
                    description: data.message,
                    onSubmit: () => {},
                    actions: [{ id: 'ok', label: 'Okay' }],
                  });
                })
                .catch((error) => {
                  return alert({
                    title: 'Nachricht konnte nicht gesendet werden',
                    description: error.message,
                    onSubmit: () => {},
                    actions: [{ id: 'ok', label: 'Okay' }],
                  });
                })
                .finally(() => {
                  setloading(false);
                });
            }}
            translation="reservations"
          >
            Resend Confirmation Email
          </Button>
        )}
      {tab === 0 &&
        [
          'seated',
          CurrentStatus.HAS_ORDERED,
          CurrentStatus.PAID,
          ...(experimentalFlags?.data?.currentTableStati?.map((s) => s.title) ??
            []),
        ].includes(status) &&
        (!!experimentalFlags?.data?.currentTableStati?.length ||
          !!experimentalFlags?.data?.allowMultipleStati) && (
          <Box>
            <Select
              options={[
                {
                  id: CurrentStatus.SEATED,
                  label: 'Sitzt',
                },
                {
                  id: CurrentStatus.HAS_ORDERED,
                  label: 'Hat bestellt',
                },
                {
                  id: CurrentStatus.PAID,
                  label: 'Hat bezahlt',
                },
                ...(experimentalFlags?.data?.currentTableStati?.map((x) => ({
                  id: x.title,
                  label: [x.icon, x.title].filter(Boolean).join(' '),
                })) ?? []),
                {
                  id: CurrentStatus.DONE,
                  label: 'Fertig',
                },
              ]}
              value={customData.customStatus || status}
              name={'Status'}
              onChange={(e) =>
                onReservationAction(
                  id,
                  ReservationActionTypes.CUSTOM_STATUS,
                  e.target.value === '-' ? '' : e.target.value
                )
              }
              className="subdued-input select"
              style={{
                width: '100%',
                padding: '4px 8px',
                border: '1px solid var(--color-border)',
                borderRadius: 4,
                textAlign: 'left',
              }}
            />
          </Box>
        )}
      {tab === 0 && ['normal', 'upcoming', 'overdue'].includes(status) && (
        <>
          {experimentalFlags?.data?.customFieldBeingLate && (
            <Box>
              <Select
                options={[
                  {
                    id: '-',
                    label: 'Keine Verspätung',
                  },
                  {
                    id: '5',
                    label: 'Verspätet um 5 Minuten',
                  },
                  {
                    id: '10',
                    label: 'Verspätet um 10 Minuten',
                  },
                  {
                    id: '15',
                    label: 'Verspätet um 15 Minuten',
                  },
                  {
                    id: '20',
                    label: 'Verspätet um 20 Minuten',
                  },
                  {
                    id: '25',
                    label: 'Verspätet um 25 Minuten',
                  },
                  {
                    id: '30',
                    label: 'Verspätet um 30 Minuten',
                  },
                  {
                    id: '45',
                    label: 'Verspätet um 45 Minuten',
                  },
                ]}
                value={customData?.['Verspätet um'] || '-'}
                name={'Verspätet um'}
                onChange={(e) =>
                  onReservationAction(
                    id,
                    ReservationActionTypes.LATE_BY,
                    e.target.value === '-' ? '' : e.target.value
                  )
                }
                className="subdued-input select"
                style={{
                  width: '100%',
                  padding: '4px 8px',
                  border: '1px solid var(--color-border)',
                  borderRadius: 4,
                  textAlign: 'left',
                }}
              />
            </Box>
          )}
          <Button
            fullWidth
            style={{
              border: '1px solid var(--color-border)',
            }}
            color="subdued"
            typographyProps={{ color: 'textPrimary' }}
            loading={loading}
            onClick={() =>
              onReservationAction(id, ReservationActionTypes.MARK_AS_LATE)
            }
            translation="reservations"
          >
            15 Minuten Später
          </Button>
          {guests > 2 && (
            <Button
              fullWidth
              style={{
                border: '1px solid var(--color-border)',
              }}
              color="subdued"
              typographyProps={{ color: 'textPrimary' }}
              loading={loading}
              onClick={() =>
                onReservationAction(id, ReservationActionTypes.PARTLY_SEAT)
              }
              translation="reservations"
            >
              Teilweise Platzieren
            </Button>
          )}
        </>
      )}
      {[
        'normal',
        'upcoming',
        'overdue',
        'seated',
        'hasOrdered',
        'paid',
        ...(experimentalFlags?.data?.currentTableStati?.map((s) => s.title) ??
          []),
      ].includes(status) && (
        <Button
          fullWidth
          endIcon={() => (
            <TableSwitch fontSize="small" style={{ marginLeft: 8 }} />
          )}
          style={{ border: '1px solid var(--color-border)' }}
          color="subdued"
          typographyProps={{ color: 'textPrimary' }}
          onClick={() => {
            if (fixed) {
              alert({
                title: 'Reservierung is fixiert',
                description:
                  'Diese Reservierung ist fixiert willst du Sie trotzdem umplatzieren?',
                onSubmit: () =>
                  onReservationAction(id, ReservationActionTypes.RELOCATE),
              });
            } else {
              onReservationAction(id, ReservationActionTypes.RELOCATE);
            }
          }}
          translation="reservations"
        >
          Relocate
        </Button>
      )}
      {status === 'normal' && (
        <Button
          fullWidth
          variant="outlined"
          color="error"
          endIcon={() => <Warning fontSize="small" style={{ marginLeft: 8 }} />}
          style={{ borderColor: 'var(--color-error)' }}
          onClick={() => onReservationAction(id, ReservationActionTypes.CANCEL)}
          translation="reservations"
        >
          Cancel
        </Button>
      )}
      {['upcoming', 'overdue', 'done'].includes(status) && (
        <Button
          fullWidth
          variant="outlined"
          color="error"
          endIcon={() => <Warning fontSize="small" style={{ marginLeft: 8 }} />}
          style={{ borderColor: 'var(--color-error)' }}
          onClick={() =>
            onReservationAction(id, ReservationActionTypes.NO_SHOW)
          }
          translation="reservations"
        >
          No Show / Cancel
        </Button>
      )}
      {[
        'seated',
        'hasOrdered',
        'paid',
        ...(experimentalFlags?.data?.currentTableStati?.map((s) => s.title) ??
          []),
      ].includes(status) && (
        <Button
          fullWidth
          style={{ border: '1px solid var(--color-border)' }}
          endIcon={() => (
            <CheckCircle color="primary" style={{ marginLeft: 8 }} />
          )}
          onClick={() =>
            onReservationAction(id, ReservationActionTypes.MARK_AS_DONE)
          }
          translation="reservations"
        >
          Mark as Done
        </Button>
      )}
      {[
        'seated',
        'hasOrdered',
        'paid',
        ...(experimentalFlags?.data?.currentTableStati?.map((s) => s.title) ??
          []),
      ].includes(status) && (
        <Button
          fullWidth
          style={{ border: '1px solid var(--color-border)' }}
          onClick={() => onReservationAction(id, ReservationActionTypes.UNSEAT)}
          translation="reservations"
        >
          Unseat
        </Button>
      )}
      {['failed', 'done'].includes(status) && (
        <>
          <Button
            style={{ border: '1px solid var(--color-border)' }}
            fullWidth
            onClick={() =>
              onReservationAction(id, ReservationActionTypes.RESEAT)
            }
            translation="reservations"
          >
            Reseat
          </Button>
          {!!creditCardCustomerId && !noShowFee && (
            <Button
              style={{ border: '1px solid var(--color-border)', marginTop: 16 }}
              fullWidth
              onClick={() =>
                onReservationAction(id, ReservationActionTypes.NO_SHOW_FEE)
              }
              translation="reservations"
            >
              No Show Gebühr einfordern
            </Button>
          )}
        </>
      )}
    </Box>
  );
};

export default ReservationButtons;
