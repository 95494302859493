import React, { useState, useEffect } from "react";
import Box from "Components/Atoms/Box";
import "./styles.scss";
import {
  Attachment,
  ReservationShift,
  ShiftComment as ShiftCommentType,
} from "types/shifts";
import useAuth from "CustomHooks/useAuth";
import { db, FieldValue, storageRef } from "utils/firebase";
import TextEditor from "Components/Molecules/TextEditor";
import Attachments from "./Components/Attachments";
import LoadingScreen from "Components/Atoms/LoadingScreen";
import { dateHelper } from "utils/helper";

export type ShiftCommentProps = {
  shiftId: string;
  date: string;
  restaurantId: string;
  templateId?: string;
  editTemplate?: boolean;
  small?: boolean;
  comment?: ShiftCommentType;
};

export const useShiftComment = (
  restaurantId: string,
  shiftId: string,
  date: string = dateHelper(),
  templateId?: string,
  editTemplate: boolean = false,
  comment?: ShiftCommentType
) => {
  const { uid } = useAuth();

  const initialShiftComment: ShiftCommentType = {
    slateDescendants: [
      {
        type: "paragraph",
        children: [{ text: "" }],
      },
    ] as any,
    attachments: [],
    createdAt: Date.now(),
    date,
    shiftId,
    createdBy: uid ?? "",
  };

  const [data, setdata] = useState(comment ?? initialShiftComment);
  const [loading, setloading] = useState(true);
  const [exists, setexists] = useState(false);
  const [listener, setlistener] = useState<any[]>([]);

  const ref = db
    .collection(`restaurants/${restaurantId}/shiftComments`)
    .doc(editTemplate && templateId ? templateId : `${date}-${shiftId}`);

  useEffect(() => {
    if (listener.length) {
      listener.forEach((x) => x());
    }

    let unsubscribe = ref.onSnapshot((doc) => {
      console.log(doc.id, doc.exists, "xx");

      if (doc.exists) {
        setdata(doc.data() as ShiftCommentType);
        setloading(false);
        setexists(true);
      } else if (templateId && !editTemplate) {
        db.collection(`restaurants/${restaurantId}/shiftComments`)
          .doc(templateId)
          .get()
          .then((doc) => {
            if (doc.exists) {
              setdata(doc.data() as ShiftCommentType);
              setloading(false);
            } else {
              setdata(comment ?? initialShiftComment);
              setloading(false);
            }
          });
      } else {
        setdata(comment ?? initialShiftComment);
        setloading(false);
      }
    });

    setlistener([unsubscribe]);

    return () => {
      if (listener.length) {
        listener.forEach((x) => x());
      }
    };
  }, [shiftId, date]);

  const onChange = (slateDescendants: ShiftCommentType["slateDescendants"]) =>
    setdata((doc) => ({
      ...doc,
      slateDescendants,
      updatedAt: Date.now(),
      updatedBy: uid ?? "",
    }));

  const onBlur = (slateDescendants: ShiftCommentType["slateDescendants"]) =>
    ref
      .update({
        slateDescendants,
        updatedAt: Date.now(),
        updatedBy: uid ?? "",
      })
      .catch(() => {
        ref.set(
          {
            ...data,
            slateDescendants,
          },
          { merge: true }
        );
      });

  const onAttachmentUpload = async (files: FileList) => {
    for (const file of files) {
      let { size, name, type } = file;

      const reference = `/restaurants/${restaurantId}/attachments/${date}-${shiftId}/${name}`;

      const fileRef = storageRef.child(reference);

      await fileRef.put(file);

      const url = await fileRef.getDownloadURL();

      const attachment: Attachment = {
        type,
        documentName: name,
        url,
        size,
        createdAt: Date.now(),
        createdBy: uid ?? "",
      };

      if (exists) {
        ref.update({ attachments: FieldValue.arrayUnion(attachment) });
      } else {
        ref.set({ ...data, attachments: [attachment] }, { merge: true });
      }
    }

    // @Show Success Message ?

    return;
  };

  const onAttachmentDelete = async (documentName: string) => {
    let attachment = data.attachments.find(
      (a) => a.documentName === documentName
    );

    if (!attachment) return;

    await ref.update({
      attachments: FieldValue.arrayRemove(attachment),
      updatedAt: Date.now(),
      updatedBy: uid ?? "",
    });
  };

  const onSave = async () => {
    setloading(true);
    ref.set(data, { merge: true });
  };

  return {
    value: data.slateDescendants,
    onChange,
    onBlur,
    attachments: data.attachments,
    onAttachmentUpload,
    onAttachmentDelete,
    loading,
    onSave,
  };
};

const ShiftComment: React.FC<ShiftCommentProps> = ({
  shiftId,
  restaurantId,
  date,
  templateId,
  comment,
  small = false,
  editTemplate = false,
}) => {
  const {
    value,
    onChange,
    onBlur,
    attachments,
    onAttachmentDelete,
    onAttachmentUpload,
    loading,
    onSave,
  } = useShiftComment(
    restaurantId,
    shiftId,
    date,
    templateId,
    editTemplate,
    comment
  );

  console.log("xx", comment, shiftId, value, templateId);

  return (
    <Box width="100%" style={{ position: "relative" }} elevation={0}>
      <LoadingScreen loading={loading} position="absolute" />
      <Attachments
        {...{ attachments, onAttachmentDelete, onAttachmentUpload }}
      />
      <TextEditor
        {...{ value, onChange, onBlur }}
        style={{ minHeight: small ? 200 : 400 }}
      />
      <button
        style={{ display: "none" }}
        onClick={onSave}
        id={`${shiftId}-${date}`}
      />
    </Box>
  );
};

export default ShiftComment;
