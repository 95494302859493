import React, { useContext } from "react";
import Box from "Components/Atoms/Box";
import IconButton from "Components/Atoms/IconButton";
import Typography, { Colors } from "Components/Atoms/Typography";
import {
  Lock,
  LockOpen,
  PartlyLocked,
  TerraceClosed,
  TerraceOpen,
} from "Components/Atoms/Icons";
import { LanguageContext } from "Contexts/LanguageContext";
import { Day } from "utils/types";
import Tooltip from "Components/Atoms/Tooltip";
import { ShiftType } from "types/shifts";
import { noop } from "utils/helper";

import "./styles.scss";

type MobileTodayProps = {
  today: Day;
  updatedAt?: number;
  totalReservations?: number;
  openReservationModal: () => void;
  handleAction: (date: string, x?: string) => void;
};

const status = (num: number) => {
  if (isNaN(num) || num === 1) return "locked";
  if (num === 0) return "open";
  return "partly";
};

const MobileToday = ({
  today,
  updatedAt,
  totalReservations,
  openReservationModal,
  handleAction,
}: MobileTodayProps) => {
  const { dayjs } = useContext(LanguageContext);

  const locked = status(
    today.shifts.reduce(
      (acc, cV) =>
        cV.type === ShiftType.RESERVATION && cV.overwrite?.blockReservations
          ? acc + 1
          : acc,
      0
    ) / today.shifts.length
  );
  const terraceOpen = false; //@TODO

  const colorHelper = (color: Colors) => {
    if (!today.open) {
      return "disabled";
    } else {
      return color;
    }
  };

  const updatedAtStr = !!updatedAt ? dayjs(updatedAt).fromNow() : "";

  const resaCount = totalReservations ?? today.reservations;

  return (
    <>
      <Box
        className="total-res-today"
        style={{ height: "inherit" }}
        onClick={noop}
      >
        <Box
          flex
          className="space-between"
          style={{ alignItems: "flex-start" }}
        >
          <Box style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="text-2"
              weight="bold"
              style={{ fontSize: "1.07rem" }}
              translation="dashboard"
            >
              Total Reservations:
            </Typography>
            <Box flex>
              <Typography
                variant={!today.open || !resaCount ? "h5" : "h3"}
                color="primary"
                style={{ fontSize: !today.open ? "1.4rem" : "1.5rem" }}
                translation="dashboard"
              >
                {today?.open
                  ? resaCount || "No Reservations"
                  : "Restaurant is closed"}
              </Typography>
            </Box>
          </Box>

          {/* icones */}
          <Box flex className="right">
            {/* météo */}
            {!!today?.weather && (
              <>
                <Typography variant="text-3">
                  {typeof today?.weather?.temp === "number"
                    ? Math.round(today.weather.temp)
                    : "-"}
                  °
                </Typography>
                <img
                  src={`https://www.weatherbit.io/static/img/icons/${today.weather.weather.icon}.png`}
                  alt={today.weather.weather.description || ""}
                  width={24}
                  height={24}
                />
              </>
            )}
            <Tooltip
              content="Open or Close Shifts for Reservations"
              translation="dashboard"
            >
              <IconButton
                aria-label="Lock MobileToday"
                disabled={!today.open}
                size="small"
                color={colorHelper("primary")}
                onClick={() => {
                  handleAction(today.date);
                  openReservationModal();
                }}
              >
                {locked === "locked" && <Lock />}
                {locked === "open" && <LockOpen />}
                {locked === "partly" && <PartlyLocked />}
              </IconButton>
            </Tooltip>
            {today.showTerrace && (
              <Tooltip
                content={terraceOpen ? "Close Terrace" : "Open Terrace"}
                translation="dashboard"
              >
                <IconButton
                  disabled={!today.open}
                  size="small"
                  color={colorHelper("tertiary")}
                >
                  {terraceOpen ? <TerraceOpen /> : <TerraceClosed />}
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
      <Box flex className="space-between" style={{ marginTop: 23 }}>
        <Typography
          variant="text-4"
          color="subdued"
          translationKey="dashboard_average"
        >
          Average: {{ avg: today.average === null ? "-" : today.average }}
        </Typography>

        {updatedAt && (
          <Typography
            variant="text-4"
            color="subdued"
            translationKey="dashboard_updated"
          >
            Updated {{ updatedAtStr }}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default MobileToday;
