import React, { useContext } from 'react';
import { CircularProgress } from '@material-ui/core';
import Box from 'Components/Atoms/Box';
import { ArrowRight, Folder as FolderIcon } from 'Components/Atoms/Icons';
import Typography from 'Components/Atoms/Typography';
import { ImageContext } from 'Contexts/ImageContext';
import InstagramColoredLogo from 'Components/Atoms/Icons/InstagramColoredLogo';

const FolderList = () => {
  const {
    folders: foldersData,
    currentFolder,
    setcurrentFolder,
    setcurrentImage,
  } = useContext(ImageContext);

  const { data: folders, loading, error } = foldersData;
  return (
    <Box
      width={200}
      style={{
        borderRight: '1px solid var(--color-border)',
        height: 'calc(90vh - 125px)',
        overflowY: 'scroll',
      }}
    >
      <Box
        className="space-between"
        style={
          currentFolder === 'general'
            ? {
                backgroundColor: 'var(--color-btn-default)',
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
            : {
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
        }
        onClick={() => setcurrentFolder('general')}
      >
        <Box flex>
          <FolderIcon
            fontSize="small"
            style={{ marginRight: 8, color: 'var(--color-text-subdued' }}
          />
          <Typography variant="text-3">All</Typography>
        </Box>
        {currentFolder === 'general' && (
          <ArrowRight style={{ color: 'var(--color-text-subdued' }} />
        )}
      </Box>
      {loading && (
        <Box className="center" style={{ marginTop: 8 }}>
          <CircularProgress />
        </Box>
      )}
      {error && (
        <Box className="center" style={{ marginTop: 8 }}>
          <Typography variant="text-2" color="error">
            {error}
          </Typography>
        </Box>
      )}
      {!!folders.length &&
        folders
          .filter((f) => f.id !== 'instagram')
          .map((folder) => (
            <Box
              className="space-between"
              style={
                currentFolder === folder.id
                  ? {
                      backgroundColor: 'var(--color-btn-default)',
                      padding: '10px 16px',
                      borderBottom: '1px solid var(--color-border)',
                    }
                  : {
                      padding: '10px 16px',
                      borderBottom: '1px solid var(--color-border)',
                    }
              }
              onClick={() => {
                setcurrentFolder(folder.id);
                setcurrentImage(null);
              }}
            >
              <Box flex>
                <FolderIcon
                  fontSize="small"
                  style={{ marginRight: 8, color: 'var(--color-text-subdued' }}
                />
                <Typography variant="text-3">{folder.name}</Typography>
              </Box>
              {currentFolder === folder.id && (
                <ArrowRight style={{ color: 'var(--color-text-subdued' }} />
              )}
            </Box>
          ))}
      <Box
        className="space-between"
        style={
          currentFolder === 'instagram'
            ? {
                backgroundColor: 'var(--color-btn-default)',
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
            : {
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
        }
        onClick={() => {
          setcurrentFolder('instagram');
          setcurrentImage(null);
        }}
      >
        <Box flex>
          <InstagramColoredLogo style={{ marginRight: 8 }} />
          <Typography variant="text-3">Instagram</Typography>
        </Box>
        {currentFolder === 'instagram' && (
          <ArrowRight style={{ color: 'var(--color-text-subdued' }} />
        )}
      </Box>
      <Box
        className="space-between"
        style={
          currentFolder === '__shared__'
            ? {
                backgroundColor: 'var(--color-btn-default)',
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
            : {
                padding: '10px 16px',
                borderBottom: '1px solid var(--color-border)',
              }
        }
        onClick={() => {
          setcurrentFolder('__shared__');
          setcurrentImage(null);
        }}
      >
        <Box flex>
          <InstagramColoredLogo style={{ marginRight: 8 }} />
          <Typography variant="text-3">Freie Bilder</Typography>
        </Box>
        {currentFolder === '__shared__' && (
          <ArrowRight style={{ color: 'var(--color-text-subdued' }} />
        )}
      </Box>
    </Box>
  );
};

export default FolderList;
