import React, { useEffect, useMemo, useState } from "react";

import "../styles.scss";
import Modal from "../../../../../Components/Molecules/Modal";
import Box from "../../../../../Components/Atoms/Box";
import TextField from "../../../../../Components/Atoms/TextField";
import { Add, Remove } from "Components/Atoms/Icons";
import IconButton from "../../../../../Components/Atoms/IconButton";
import { blockToTime } from "../../../../../utils/helper";
import SwitchBox from "../../../../../Components/Atoms/Switch";
import { ReservationLengthRule } from "../../../../../types/shifts";
import useIsLightReservations from "CustomHooks/useIsLightReservations";
import MenuItem from "Components/Atoms/MenuItem";
import { ReservationShift } from "gastronaut-shared/types/helper/shifts";

interface AddEditReservationModalProps {
  open: boolean;
  modalType: "add" | "edit";
  occassions: { id: string; title: string }[];
  onAddRule: (rule: ReservationLengthRule) => void;
  onEditRule: (rule: ReservationLengthRule) => void;
  initialData: ReservationLengthRule | {};
  onClose: () => void;
  rulesLength: number;
  currentShift?: Partial<ReservationShift>;
  minLength?: number;
}

const AddEditReservationRuleModal = ({
  open,
  modalType,
  occassions,
  onAddRule,
  onEditRule,
  initialData,
  onClose,
  rulesLength,
  currentShift,
  minLength = 1,
}: AddEditReservationModalProps) => {
  const [toggleMinGuests, setToggleMinGuests] = useState<boolean>(false);
  const [newRule, setNewRule] = useState<ReservationLengthRule>({
    length: 8,
    occassions: [],
    vip: false,
    specialOccassion: false,
    index: rulesLength,
  });

  const isLight = useIsLightReservations();

  useEffect(() => {
    if (!!Object.keys(initialData).length) {
      const { minGuests, length, occassions, vip, specialOccassion, index } =
        initialData as ReservationLengthRule;
      setToggleMinGuests(!!minGuests);
      setNewRule({
        length,
        minGuests,
        occassions: occassions ? [...occassions] : [],
        vip,
        specialOccassion,
        index,
      });
    }
  }, [initialData]);

  const toggleNumberField =
    (key: "minGuests" | "from" | "till", defaultVal = 4) =>
    () => {
      let newR = { ...newRule };
      if (newR[key] === undefined) {
        newR[key] = defaultVal;
      } else {
        delete newR[key];
      }
      setNewRule(newR);
    };

  const handleClose = () => {
    setNewRule({
      length: 8,
      minGuests: 4,
      occassions: [],
      vip: false,
      specialOccassion: false,
      index: rulesLength,
    });
    onClose();
  };

  const times = useMemo(() => {
    let start = currentShift?.start ?? 32;
    let end = currentShift?.close ?? 112;

    return Array.from(new Array(end - start), (_, i) => i + start).map(
      (value) => ({
        value,
        label: blockToTime(value),
      })
    );
  }, [currentShift]);

  return (
    <Modal
      open={open}
      width={414}
      titleTranslation="settings"
      title={
        !!Object.keys(initialData).length ? "Edit a Rule" : "Create a Rule"
      }
      onClose={handleClose}
      actions={[
        {
          id: "cancel",
          label: "Cancel",
          onClick: handleClose,
        },
        {
          id: "submit",
          label: "Save",
          onClick: () =>
            modalType === "add" ? onAddRule(newRule) : onEditRule(newRule),
          variant: "primary",
        },
      ]}
    >
      <Box className="modal-container">
        <Box className="left">
          <SwitchBox
            checked={newRule.minGuests !== undefined}
            onChange={toggleNumberField("minGuests")}
            label={
              <TextField
                name="size"
                type="number"
                label="Min. Group Size"
                labelTranslation="settings"
                value={newRule.minGuests ?? ""}
                fullWidth
                onChange={(e) =>
                  setNewRule({
                    ...newRule,
                    minGuests: parseInt(e.target.value),
                  })
                }
                disabled={newRule.minGuests === undefined}
                style={{ width: "160px" }}
                InputLabelProps={{
                  shrink: newRule.minGuests !== undefined,
                }}
              />
            }
          />
          <SwitchBox
            checked={newRule.from !== undefined}
            onChange={toggleNumberField("from", currentShift?.start ?? 72)}
            label={
              <TextField
                name="size"
                type="number"
                label="Ab X Uhr"
                labelTranslation="settings"
                value={newRule.from ?? ""}
                fullWidth
                onChange={(e) =>
                  setNewRule({
                    ...newRule,
                    from: e.target.value ? parseInt(e.target.value) : undefined,
                  })
                }
                disabled={newRule.from === undefined}
                style={{ width: "160px" }}
                select
                InputLabelProps={{
                  shrink: newRule.from !== undefined,
                }}
              >
                <MenuItem>Keine Zeit</MenuItem>
                {times.map((t) => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <SwitchBox
            checked={newRule.till !== undefined}
            onChange={toggleNumberField("till", currentShift?.service ?? 96)}
            label={
              <TextField
                name="size"
                type="number"
                label="Bis X Uhr"
                labelTranslation="settings"
                value={newRule.till ?? ""}
                fullWidth
                onChange={(e) =>
                  setNewRule({
                    ...newRule,
                    till: e.target.value ? parseInt(e.target.value) : undefined,
                  })
                }
                disabled={newRule.till === undefined}
                style={{ width: "160px" }}
                error={
                  !!(
                    newRule.from &&
                    newRule.till &&
                    newRule.from > newRule.till
                  )
                }
                errorMessage={
                  newRule.from && newRule.till && newRule.from > newRule.till
                    ? "Ab muss vor Bis liegen."
                    : ""
                }
                select
                InputLabelProps={{
                  shrink: newRule.till !== undefined,
                }}
              >
                <MenuItem>Keine Zeit</MenuItem>
                {times.map((t) => (
                  <MenuItem key={t.value} value={t.value}>
                    {t.label}
                  </MenuItem>
                ))}
              </TextField>
            }
          />
          <SwitchBox
            checked={newRule.vip ?? false}
            onChange={() =>
              setNewRule({
                ...newRule,
                vip: !newRule.vip,
              })
            }
            label="Regular Guest"
            translation="settings"
          />
          <SwitchBox
            checked={newRule.specialOccassion ?? false}
            onChange={() =>
              setNewRule({
                ...newRule,
                specialOccassion: !newRule.specialOccassion,
              })
            }
            label="Special Occassion"
            translation="settings"
          />
          {!isLight &&
            (occassions.length > 1 || !!newRule.occassions?.length) &&
            occassions.map((o) => (
              <SwitchBox
                checked={!!newRule.occassions?.includes?.(o.id)}
                onChange={(_, checked) =>
                  setNewRule({
                    ...newRule,
                    occassions: checked
                      ? newRule.occassions
                        ? [...newRule.occassions, o.id]
                        : [o.id]
                      : newRule.occassions?.filter((occ) => occ !== o.id),
                  })
                }
                label={o.title}
              />
            ))}
        </Box>
        <Box className="right">
          <IconButton size="small">
            <Add
              onClick={() =>
                setNewRule({ ...newRule, length: (newRule?.length ?? 0) + 1 })
              }
            />
          </IconButton>
          {blockToTime(newRule?.length as number)}
          <IconButton
            size="small"
            disabled={minLength >= (newRule?.length ?? minLength + 1)}
          >
            <Remove
              onClick={() =>
                minLength < (newRule?.length ?? minLength + 1) &&
                setNewRule({
                  ...newRule,
                  length: (newRule?.length ?? minLength + 1) - 1,
                })
              }
            />
          </IconButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default AddEditReservationRuleModal;
